import React, { useState } from "react";
import styled from "styled-components";

const Wrap = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props.visible ? "9999999999" : props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

function TextOverflow({ children, lines = 5, mode }) {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };
  return (
    <Wrap
      visible={visible}
      onClick={mode === "withShow" ? toggleVisible : () => {}}
      lines={lines}
    >
      {children}
    </Wrap>
  );
}

export default TextOverflow;
