import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { rem } from "utils/common";
import { colors, maxW, someStyle, device } from "components/variable";

import Calendar from "static/images/Calendar.svg";
import Calendar2 from "static/images/Calendar2.svg";
import ArrowLeft from "static/images/arrowLeft.svg";
import ArrowRight from "static/images/arrowRight.svg";

export const PickerWrap = styled.div`
  .react-datepicker {
    font-family: inherit;
    font-size: 1rem;
    background-color: ${colors.white};
    color: ${colors.black};
    border: 1px solid ${colors.yellow};
    padding-top: ${rem(27)};
    &__input-container {
      input {
        font-size: ${rem(18)};
        border: none;
        padding: ${rem(3)} ${rem(8)};
        width: ${rem(272)};
        padding-right: ${rem(35)};
        background: url(${Calendar}) right center no-repeat;
        background-position-x: 97%;
        text-align: right;
        &:hover {
          background: url(${Calendar2}) right center no-repeat;
          background-position-x: 97%;
        }
      }
      .react-datepicker-ignore-onclickoutside {
        background: url(${Calendar2}) right center no-repeat;
        background-position-x: 97%;
      }
    }
    &__header {
      background: ${colors.white};
      border-bottom: 0;
    }
    &__current-month {
      margin-bottom: ${rem(35)};
      font-weight: 600;
      font-size: ${rem(16)};
    }
    &__month {
      margin-top: 0;
    }
    &__day {
      border: none;
      color: ${colors.darkGrey};
      &:hover {
        background: transparent;
        color: ${colors.grey};
      }
      &-name {
        color: ${colors.grey};
      }
      &--selected {
        border-radius: 50%;
        background: ${colors.red};
        color: ${colors.white};
        font-weight: bold;
      }
    }
    &__navigation {
      top: ${rem(33)};
      height: ${rem(24)};
      width: ${rem(24)};
      border: none;
      &--previous {
        background: url(${ArrowLeft});
        left: ${rem(50)};
      }
      &--next {
        background: url(${ArrowRight});
        right: ${rem(50)};
      }
    }
    &__month-container {
      padding: 0 ${rem(34)};
    }
    &__input-time-container {
      background: ${colors.lightGrey};
      padding: ${rem(26)} ${rem(34)};
      margin-top: ${rem(25)};
      margin: 0;
    }
    &-time__caption {
      display: none;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: ${rem(45)};
    height: ${rem(45)};
    margin: ${rem(0)};
    padding-top: ${rem(9)};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: ${colors.red};
    border-radius: 50%;
  }
`;

export const TimeInput = styled.div`
  .time-picker {
    .react-time-picker__wrapper {
      border: solid 1px ${colors.grey};
      width: ${rem(155)}!important;
      background: ${colors.white};
      text-align: center;
      color: ${colors.black};
      font-weight: bold;
      padding: ${rem(10)} ${rem(10)};
      font-size: ${rem(13)};
    }
  }
`;
