import { rem } from "utils/common";
import { device, colors } from "components/variable";
import styled from "styled-components";

export const Col = styled.div`
  padding: 0 !important;
  @media ${device.laptopS} {
    padding-bottom: ${rem(20)}!important;
    &:last-child {
      border: none;
    }
  }
  @media ${device.laptopXS} {
    border-right: ${rem(2)} solid ${colors.yellow};
  }
`;

export const Left = styled.div`
  padding: 0 ${rem(20)};
  @media ${device.laptopS} {
    padding: 0 ${rem(30)};
  }
`;

export const Center = styled.div`
  margin: 0 auto;
  margin-top: ${rem(20)};
  padding: 0 ${rem(15)};
  display: flex;
  flex-direction: column-reverse;
  @media ${device.laptopS} {
    display: block;
    margin: 0 auto;
    padding-top: ${rem(44)};
    max-width: ${rem(530)};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const CenterContent = styled.div`
  margin-top: ${rem(10)};
  @media ${device.laptopS} {
    margin-top: ${rem(80)};
  }
`;

export const Right = styled.div`
  position: relative;
  max-width: ${rem(510)};
  margin: 0 auto;
  padding: 0 ${rem(15)};
  padding-top: ${rem(30)};
  @media ${device.laptopS} {
    height: 100%;
    max-width: ${rem(370)};
    padding-top: ${rem(44)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => (props.theme === "small" ? rem(10) : rem(20))};
`;

export const BidWrap = styled.div`
  display: flex;
  margin: 0 ${rem(-10)};
  flex-wrap: wrap;
  .bidInput {
    width: 100%;
    border: 1px solid ${colors.grey};
    padding: ${rem(10)} ${rem(13)};
    padding-left: ${rem(8)};
    padding-right: ${rem(25)};
    font-weight: bold;
    @media ${device.laptopM} {
      padding: ${rem(10)} ${rem(20)};
      padding-right: ${rem(25)};
    }
  }
`;

export const Bid = styled.div`
  position: relative;
  width: 33.3333333%;
  padding: ${rem(10)};
`;

export const CloseWrap = styled.div`
  position: absolute;
  top: ${rem(22)};
  right: ${rem(20)};
  cursor: pointer;
`;
