import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { keysActions } from "store";
import copy from "copy-to-clipboard";

import LayoutSettings from "components/layout/settings";

import CopyBlock from "components/copy";
import Preloader from "components/preloader";
import { TextInput } from "components/form/input";
import { CodesWrap, CopyWrap } from "./styled";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

function KeyCodes() {
  const [changePassword, setChangePassword] = useState(false);

  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => {
    return {
      list: state.keys.list,
      loading: state.keys.loading,
    };
  }, shallowEqual);

  const onGenerate = (values) => {
    dispatch(keysActions.generateStart(values));
  };
  const content = loading ? (
    <Preloader />
  ) : (
    <>
      <Formik
        initialValues={{
          count: 0,
        }}
        validationSchema={Yup.object({
          count: Yup.number().min(1).required(),
        })}
        onSubmit={onGenerate}
      >
        {({ handleSubmit, isValid, error }) => {
          return (
            <FormWrap
              theme="center"
              className="d-flex justify-content-between align-items-center pt-4 mt-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-24">
                <SmallText className="mb-1">Quantity</SmallText>
                <TextInput
                  name="count"
                  type="number"
                  theme="number"
                  className="text-center"
                  mode="noError"
                />
              </div>
              <Button
                mode="small"
                color="lightYellow"
                textColor="green"
                disabled={!isValid || error}
              >
                Generate
              </Button>
            </FormWrap>
          );
        }}
      </Formik>
      {list.length > 0 && <CopyBlock list={list} />}
    </>
  );

  return (
    <LayoutSettings title="Generate KeyCodes" mode="second">
      {content}
    </LayoutSettings>
  );
}

export default KeyCodes;
