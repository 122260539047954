import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import history from "utils/history";

import AppContainer from "containers/App";

import { store } from "./store";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContainer />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
