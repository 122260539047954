export default {
  location: window.location,
  userList: {
    limit: 15,
  },
  lots: {
    limit: 3,
  },
  bids: {
    min: 5,
    limit: 10,
    delay: 1000,
    checkMark: 1000,
  },
  timer: {
    delay: 10000,
  },
};
