import React from "react";
import styled from "styled-components";
import { rem } from "utils/common";
import { colors } from "components/variable";
import Image from "static/images/lot.jpg";

const Wrap = styled.div`
  width: 100%;
  max-height: ${(props) => (props.mode === "single" ? rem(320) : rem(330))};
  min-height: ${(props) => (props.mode === "single" ? rem(320) : "100%")};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.color ? colors[props.color] : colors.red)};
`;

function ImagePreview({ image, ...props }) {
  return (
    <Wrap {...props}>
      <img src={image || Image} alt="" className="w100" {...props} />
    </Wrap>
  );
}

export default ImagePreview;
