import React, { memo } from "react";
import Modal from "./index";
import styled from "styled-components";
import { colors } from "components/variable";
import { rem } from "utils/common";
import { Icon } from "components/icon";
import Button from "components/button";
import Preloader from "components/preloader";
import { WelcomeTitle } from "components/styled";

const Wrap = styled.div`
  padding: ${rem(35)} ${rem(20)};
  max-width: ${rem(900)};
`;

const CloseBlock = styled.div`
  position: absolute;
  top: ${rem(45)};
  right: ${rem(70)};
`;

const customStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 0.8)",
    backdropFilter: "blur(44px)",
    zIndex: 9999,
  },
};

const ConfirmModal = memo((props) => {
  const {
    visible,
    text,
    contentMain,
    textNo,
    textOk,
    onCancel,
    onSave,
    loading,
  } = props;

  let content = null;

  if (loading) {
    content = (
      <div>
        <Preloader />
      </div>
    );
  } else {
    content = (
      <>
        <WelcomeTitle color="white">{text}</WelcomeTitle>
        {contentMain}
        <div className="mt-64 d-flex justify-content-center justify-content-md-start">
          <Button theme="outside" onClick={onCancel}>
            {textNo}
          </Button>
          <Button className="ml-4" onClick={onSave} color="black">
            {textOk}
          </Button>
        </div>
      </>
    );
  }

  return (
    <Modal visible={visible} style={customStyles}>
      <CloseBlock>
        <Icon
          component="Close"
          onClick={onCancel}
          className="cursor-pointer pathHover"
        />
      </CloseBlock>
      <Wrap>{content}</Wrap>
    </Modal>
  );
});

ConfirmModal.defaultProps = {
  textNo: "No",
  textOk: "Yes",
  contentMain: null,
  onCancel: function () {},
  onSave: function () {},
};

export default ConfirmModal;
