import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute, { AdminRoute } from "components/private-route";

import { useDispatch } from "react-redux";
import { isAuth } from "utils/auth";

import AdminPanel from "containers/Admin";
import UserPanel from "containers/UserPanel";
import Auth from "containers/Auth";
import Home from "containers/Home";
import LotPage from "containers/Lot";
import Settings from "containers/Settings";

import { store, profileActions } from "store";

function AppContainer() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth()) {
      dispatch(profileActions.getUserRequest());
    }
  }, [isAuth]);
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/auth" component={Auth} />
      <PrivateRoute exact path="/lots" exact component={UserPanel} />
      <PrivateRoute path="/lot/:id" exact component={LotPage} />
      <PrivateRoute path="/settings" component={Settings} />
      <AdminRoute path="/admin" component={AdminPanel} />
      <Route render={() => <Redirect to="/lots" />} />
    </Switch>
  );
}

export default AppContainer;
