import { rem } from "utils/common";
import { device, colors } from "components/variable";
import styled from "styled-components";

export const CodesWrap = styled.div`
  position: relative;
  max-width: ${rem(360)};
  margin: ${rem(35)} auto;
  padding: ${rem(12)} ${rem(20)};
  padding-right: ${rem(40)};
  color: ${colors.black};
  font-size: ${rem(18)};
  border: 1px solid ${colors.grey};
`;

export const CopyWrap = styled.div`
  position: absolute;
`;
