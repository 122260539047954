import environment from "environment";

export class UploadModel {
  constructor(data) {
    data = data || {};
    this.id = data._id || data.id;
    this.url = null;

    if (data.url) {
      this.url = `${environment.url}/${data.url}`;
    }
  }
}
