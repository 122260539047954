import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";

import { PickerWrap, TimeInput } from "./styled";

const ExampleCustomTimeInput = ({ value, onChange }) => {
  const regexp = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
  return (
    <TimeInput>
      <TimePicker
        className="time-picker"
        value={value}
        format="h:mm a"
        disableClock
        onChange={(time) => {
          if (time && time.toString().match(regexp)) {
            onChange(time);
          }
        }}
      />
    </TimeInput>
  );
};

export const Picker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <PickerWrap>
      <DatePicker
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        dateFormat="MMMM dd, yyyy h:mm a"
        showTimeInput
        customTimeInput={<ExampleCustomTimeInput />}
        {...field}
        {...props}
        minDate={Date.now.toString()}
      />
    </PickerWrap>
  );
};
