import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import Layout from "components/layout/auth";
import Button from "components/button";

function Home() {
  return (
    <Layout title="Anonym Auction" description="is welcoming you">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start">
        <Link to="auth/login">
          <Button>Log In</Button>
        </Link>
        <Link className="ml-4" to="auth/signup">
          <Button>Registration</Button>
        </Link>
      </div>
    </Layout>
  );
}

export default Home;
