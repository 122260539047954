import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Redirect } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { loginStart } from "store";

import styled from "styled-components";
import Layout from "components/layout/auth";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

function Login() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => {
    return {
      isAuth: state.auth.isAuth,
    };
  }, shallowEqual);

  const onLogin = (values) => {
    dispatch(loginStart(values));
  };

  if (isAuth) {
    return <Redirect to="/lots" />;
  }
  return (
    <Layout
      title="Log In"
      description="if you have an account"
      linkText="Registration"
      href="/auth/signup"
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string().required("required"),
          email: Yup.string().email("wrong email").required("required"),
        })}
        onSubmit={(values) => {
          onLogin(values);
        }}
      >
        {({ handleSubmit, isValid, error, handleChange }) => {
          return (
            <FormWrap onSubmit={handleSubmit}>
              <InputWithLabel
                label="Email"
                name="email"
                type="email"
                handleChange={handleChange}
                firstField
              />
              <InputWithLabel
                label="Password"
                name="password"
                type="password"
                handleChange={handleChange}
              />
              <SmallText className="mb-24 mt-8 text-right">
                <Link to="/auth/reset-password">forgot password</Link>
              </SmallText>
              <Button
                color={isValid && !error ? "red" : "grey"}
                disabled={!isValid || error}
                className="mt-40"
              >
                Log in
              </Button>
            </FormWrap>
          );
        }}
      </Formik>
    </Layout>
  );
}

export default Login;
