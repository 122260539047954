import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { compose } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import queryString from "query-string";
import config from "_config";
import { resend } from "store";

import Layout from "components/layout/auth";
import Register from "containers/Auth/register";
import Login from "containers/Auth/login";
import Verified from "containers/Auth/verified";
import RestorePassword from "containers/Auth/restore";
import ResetPassword from "containers/Auth/reset";
// import injectReducer from 'utils/injectReducer'
// import { reducer } from './slices'
import { SmallText } from "components/styled";
import Button, { ButtonLink } from "components/button";

const Auth = (props) => {
  const { match } = props;
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => {
    return {
      isAuth: state.auth.isAuth,
    };
  }, shallowEqual);
  const onResend = (type) => () => {
    const payload = {
      type: type,
      ...queryString.parse(config.location.search),
    };
    dispatch(resend({ ...payload }));
  };
  const path = {
    auth: `${match.path}`,
    login: `${match.path}/login`,
    register: `${match.path}/signup`,
    restore: `${match.path}/restore-password`,
    reset: `${match.path}/reset-password`,
    registerSuccess: `${match.path}/signup/success`,
    verified: `${match.path}/signup/verified`,
    resetSuccess: `${match.path}/reset-password/success`,
    guest: `${match.path}/guest`,
  };

  return (
    <Switch>
      <Route exact path={path.register} component={Register} />
      <Route exact path={path.login} component={Login} />
      <Route exact path={path.restore} component={RestorePassword} />
      <Route exact path={path.reset} component={ResetPassword} />
      <Route exact path={path.verified} component={Verified} />
      <Route
        exact
        path={path.registerSuccess}
        render={() => (
          <Layout
            title="Congrats!"
            description="You are successfully registered. Verify, please, your email and login"
          >
            {
              <SmallText colorSpan="darkGrey">
                Press{" "}
                <span
                  onClick={onResend("email")}
                  className="medium cursor-pointer text-underline "
                >
                  resend
                </span>{" "}
                if you didn't get the email
              </SmallText>
            }
          </Layout>
        )}
      />
      <Route
        exact
        path={path.resetSuccess}
        render={() => (
          <Layout
            title="Email has been send"
            description="Please, check your inbox"
          >
            <SmallText colorSpan="darkGrey">
              Press{" "}
              <span
                onClick={onResend("password")}
                className="medium cursor-pointer text-underline"
              >
                resend
              </span>{" "}
              if you didn't get the email
            </SmallText>
          </Layout>
        )}
      />

      <Route path={path.auth} render={() => <Redirect to={path.login} />} />
    </Switch>
  );
};

export default Auth;
