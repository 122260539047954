import React, { useEffect, useState } from "react";
import { useDidMount, useWillUnmount } from "beautiful-react-hooks";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Info from "./component/info";
import Bid from "./component/bid";
import { lotActions, lotsActions } from "store";
import config from "_config";
import socketSrv from "services/socket";
import { getToken } from "utils/auth";

import LayoutSimple from "components/layout/simple";
import Preloader from "components/preloader";
import SimpleModal from "components/modal/simple";
import ConfirmModal from "components/modal/confirm";
import { WelcomeTitle } from "components/styled";
import { ButtonLink } from "components/button";
import { useHistory } from "react-router-dom";

function LotPage({ match: { params } }) {
  const [connected, setConnected] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    bidAdded,
    bid,
    modalWinner,
    modalDelete,
    user,
    lot,
  } = useSelector((state) => {
    return {
      user: state.profile.user,
      lot: state.lot.lot,
      bidAdded: state.lot.bidAdded,
      bid: state.lot.currentBid,
      modalWinner: state.lot.modalWinner,
      modalDelete: state.lots.modalDelete,
      loading: state.lot.loading,
    };
  }, shallowEqual);

  const { instruction } = lot;

  const bidWorker = (e) => {
    let { bid, currentPrice, estimate, eventType } = e;
    if (bid.lotId === lot.id) {
      dispatch(
        lotActions.bidEnd({ currentPrice, bid: { ...bid, type: "ws" } })
      );
      const payload = {
        event: "listen",
        payload: {
          lotId: bid.lotId,
        },
      };
      socketSrv.send(payload);
    }
  };

  const lotWorker = (e) => {
    const { eventType, winner, lotId } = e;
    if (eventType === "end" && lotId === lot.id) {
      dispatch(lotActions.lotWinner(winner));
    }
  };

  useDidMount(() => {
    socketSrv.on("disconnect", () =>
      dispatch(lotActions.getLotStart({ id: params.id }))
    );
    dispatch(lotActions.getLotStart({ id: params.id }));
    socketSrv.set({
      queryParams: `?jwt=${getToken()}&lotId=${params.id}`,
    });
    if (!socketSrv.connected) {
      socketSrv.connect();
      setConnected(true);
    }
  }, []);

  useEffect(() => {
    if (lot.id) {
      socketSrv.on("bid", bidWorker);
      socketSrv.on("lot", lotWorker);
    }
  }, [lot.id]);

  useWillUnmount(() => {
    socketSrv.disconnect();
    setConnected(false);
  });

  const onDelete = () => {
    dispatch(lotsActions.deleteLotModal());
  };

  const deleteLot = () => {
    dispatch(lotsActions.deleteLotStart({ id: lot.id }));
  };

  const closeWinner = () => {
    dispatch(lotActions.modalClose());
    history.push(`/lots`);
  };

  const content = loading ? (
    <Preloader />
  ) : (
    user.id &&
    lot.id &&
    connected && (
      <>
        <div className="d-flex flex-wrap">
          <Info lot={lot} user={user} />
          <Bid lot={lot} user={user} bidAdded={bidAdded} onDelete={onDelete} />
        </div>
        <SimpleModal
          onCancel={closeWinner}
          mode={lot.winner.id ? "cool" : ""}
          visible={modalWinner}
        >
          <WelcomeTitle color="white" className="medium">
            {lot.winner.id ? (
              <span>The winner is {lot.winner.name}</span>
            ) : (
              <span>This auction has ended</span>
            )}
          </WelcomeTitle>
          <div className="d-flex justify-content-center justify-content-md-start">
            <ButtonLink
              href="/lots"
              onClick={closeWinner}
              className="mt-64 mb-64"
            >
              Go to Main
            </ButtonLink>
          </div>
        </SimpleModal>
        <ConfirmModal
          text="Are you sure that you want to delete Lot?"
          visible={modalDelete}
          onSave={deleteLot}
          onCancel={() => dispatch(lotsActions.deleteLotModal())}
        />
      </>
    )
  );

  return (
    <LayoutSimple instruction={instruction} mode="withInfo">
      {content}
    </LayoutSimple>
  );
}

export default LotPage;
