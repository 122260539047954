import React from "react";
import { Icon } from "components/icon";
import copy from "copy-to-clipboard";
import { rem } from "utils/common";
import { device, colors } from "components/variable";
import styled from "styled-components";

export const CodesWrap = styled.div`
  position: relative;
  max-width: ${rem(360)};
  margin: ${rem(35)} auto;
  padding: ${rem(12)} ${rem(20)};
  padding-right: ${rem(40)};
  color: ${colors.black};
  font-size: ${rem(18)};
  line-height: ${rem(20)};
  border: 1px solid ${colors.grey};
`;

export const CopyWrap = styled.div`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
`;

function CopyBlock({ list }) {
  const onCopy = () => {
    copy(list.join("\r\n"));
  };
  return (
    <CodesWrap>
      {list.map((item, idx) => (
        <div key={idx}>{item}</div>
      ))}
      <CopyWrap className="cursor-pointer" onClick={onCopy}>
        <Icon component="CopyIcon" />
      </CopyWrap>
    </CodesWrap>
  );
}

export default CopyBlock;
