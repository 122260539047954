import React, { memo } from "react";
import { useField } from "formik";
import styled, { css } from "styled-components";
import { colors } from "components/variable";
import { rem } from "utils/common";
const { grey, red } = colors;

const WrapToContent = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: ${rem(20)};
  ${(props) =>
    props.theme === "number" &&
    css`
      margin-bottom: 0;
      max-width: ${rem(160)};
    `};
  box-sizing: border-box;
  .errorContent {
    color: ${colors.redInput};
    text-align: left;
  }
  input {
    border: 1px solid
      ${(props) => (props.error ? colors.redInput : colors.grey)};
  }
  textarea {
    border: 1px solid
      ${(props) => (props.error ? colors.redInput : colors.grey)};
  }
`;

const base = (props) => {
  return css`
    width: 100%;
    box-sizing: border-box;
    padding: ${rem(20)};
    padding-left: ${rem(10)};
    border: 1px solid ${props.error ? red : grey};
    font-size: ${rem(18)};
    line-height: ${rem(20)};
    color: ${grey};
    ::placeholder {
      color: ${grey};
      font-size: ${rem(18)};
    }
    &::-webkit-input-placeholder {
      color: ${grey};
      font-size: ${rem(18)};
    }
    &::-moz-placeholder {
      color: ${grey};
      font-size: ${rem(18)};
    }
    &:-ms-input-placeholder {
      color: ${grey};
      font-size: ${rem(18)};
    }
    &:-moz-placeholder {
      color: ${grey};
      font-size: ${rem(18)};
    }
    :disabled {
      background: ${grey};
    }
  `;
};

const Input = styled.input`
  ${(props) => base(props)};
  ${(props) =>
    props.theme === "number" &&
    css`
      padding: ${rem(4)} ${rem(10)};
      color: ${colors.black};
      font-weight: bold;
    `};
`;

const Area = styled.textarea`
  ${(props) => base(props)};
  font-family: "Graphik";
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 500px;
  color: ${colors.black};
`;

export const TextInput = memo(({ placeholder, label, ...props }) => {
  const { id, name } = props;
  const [field, meta] = useField({ ...props });
  let place = props.required ? placeholder + "*" : placeholder;
  let content;
  if (props.type === "area") {
    content = <Area {...field} {...props} placeholder={place} />;
  } else {
    content = <Input {...field} {...props} placeholder={place} />;
  }
  return (
    <WrapToContent
      className="itemInp"
      theme={props.theme}
      value={field.value}
      error={meta.error}
    >
      {content}
      {meta.touched && meta.error && props.mode !== "noError" ? (
        <div className="errorContent">{meta.error}</div>
      ) : null}
    </WrapToContent>
  );
});
