import React from "react";
import { colors } from "components/variable";

export const Logo = (props) => (
  <svg
    {...props}
    width="103"
    height="30"
    viewBox="0 0 103 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.1609 9.86037C42.2337 9.86037 41.4751 9.63077 40.8851 9.17159C40.295 8.70032 40 7.99342 40 7.05089C40 6.01168 40.4395 5.2504 41.3186 4.76705C42.1976 4.2837 43.3717 4.04202 44.8407 4.04202H46.0148V3.55263C46.0148 2.90011 45.8823 2.4228 45.6174 2.1207C45.3645 1.80653 44.925 1.64944 44.2989 1.64944C43.2031 1.64944 42.601 2.14487 42.4926 3.13574H40.3793C40.4636 2.08445 40.873 1.29901 41.6076 0.779404C42.3541 0.259801 43.2934 0 44.4253 0C46.942 0 48.2004 1.154 48.2004 3.462V9.67911H46.0509V8.51907C45.3766 9.41327 44.4133 9.86037 43.1609 9.86037ZM43.6847 8.28343C44.3711 8.28343 44.9311 8.11426 45.3645 7.77591C45.798 7.42548 46.0148 6.95422 46.0148 6.36211V5.41958H44.8949C43.0766 5.41958 42.1675 5.93314 42.1675 6.96026C42.1675 7.84237 42.6732 8.28343 43.6847 8.28343Z"
      fill="black"
    />
    <path
      d="M49.8023 9.67911V0.199383H51.9878V1.70381C52.2287 1.20838 52.614 0.803571 53.1438 0.489393C53.6737 0.163131 54.3058 0 55.0404 0C56.0398 0 56.8286 0.308136 57.4066 0.924409C57.9846 1.5286 58.2736 2.45905 58.2736 3.71576V9.67911H56.088V3.91515C56.088 3.2022 55.9375 2.67656 55.6364 2.33821C55.3354 1.99987 54.8598 1.83069 54.2095 1.83069C53.5593 1.83069 53.0234 2.03008 52.6019 2.42884C52.1925 2.81552 51.9878 3.37137 51.9878 4.0964V9.67911H49.8023Z"
      fill="black"
    />
    <path
      d="M64.1149 8.15655C64.9217 8.15655 65.5539 7.87863 66.0115 7.32277C66.4811 6.75483 66.7159 5.98147 66.7159 5.00269V4.87581C66.7159 3.89702 66.4811 3.1297 66.0115 2.57385C65.5539 2.00591 64.9217 1.72194 64.1149 1.72194C63.2961 1.72194 62.6519 1.99987 62.1822 2.55572C61.7247 3.11157 61.4959 3.87889 61.4959 4.85768V5.00269C61.4959 5.98147 61.7247 6.75483 62.1822 7.32277C62.6519 7.87863 63.2961 8.15655 64.1149 8.15655ZM67.5829 8.51907C66.6678 9.41327 65.5057 9.86037 64.0969 9.86037C62.688 9.86037 61.526 9.41327 60.6108 8.51907C59.7077 7.62487 59.2561 6.46482 59.2561 5.03894V4.89393C59.2561 3.44388 59.7137 2.26571 60.6289 1.35943C61.544 0.453141 62.7061 0 64.1149 0C65.5118 0 66.6678 0.4471 67.5829 1.3413C68.4981 2.2355 68.9557 3.40158 68.9557 4.83955V4.98456C68.9557 6.4467 68.4981 7.62487 67.5829 8.51907Z"
      fill="black"
    />
    <path
      d="M70.0273 9.67911V0.199383H72.2128V1.70381C72.4537 1.20838 72.839 0.803571 73.3688 0.489393C73.8987 0.163131 74.5308 0 75.2654 0C76.2648 0 77.0536 0.308136 77.6316 0.924409C78.2096 1.5286 78.4986 2.45905 78.4986 3.71576V9.67911H76.313V3.91515C76.313 3.2022 76.1625 2.67656 75.8614 2.33821C75.5604 1.99987 75.0848 1.83069 74.4345 1.83069C73.7843 1.83069 73.2484 2.03008 72.827 2.42884C72.4175 2.81552 72.2128 3.37137 72.2128 4.0964V9.67911H70.0273Z"
      fill="black"
    />
    <path
      d="M80.8964 12.9236L82.6123 8.71845L78.8734 0.199383H81.2395L83.7502 6.30773L86.0622 0.199383H88.1936L83.0458 12.9236H80.8964Z"
      fill="black"
    />
    <path
      d="M88.9294 9.67911V0.199383H91.1149V1.64944C91.3557 1.17817 91.729 0.785446 92.2348 0.471268C92.7405 0.157089 93.3246 0 93.9868 0C95.3114 0 96.2025 0.567937 96.6601 1.70381C97.0093 1.14796 97.4729 0.725027 98.0509 0.435016C98.6289 0.145005 99.231 0 99.8571 0C100.784 0 101.537 0.308136 102.115 0.924409C102.705 1.5286 103 2.44697 103 3.67951V9.67911H100.814V3.82452C100.814 2.4953 100.255 1.83069 99.1346 1.83069C98.5566 1.83069 98.0629 2.02403 97.6535 2.41071C97.2561 2.78531 97.0575 3.317 97.0575 4.00577V9.67911H94.8719V3.82452C94.8719 2.4953 94.312 1.83069 93.1921 1.83069C92.6141 1.83069 92.1204 2.02403 91.711 2.41071C91.3136 2.78531 91.1149 3.317 91.1149 4.00577V9.67911H88.9294Z"
      fill="black"
    />
    <path
      d="M43.1609 30C42.2337 30 41.4751 29.7704 40.8851 29.3112C40.295 28.84 40 28.1331 40 27.1905C40 26.1513 40.4395 25.39 41.3186 24.9067C42.1976 24.4233 43.3717 24.1817 44.8407 24.1817H46.0148V23.6923C46.0148 23.0397 45.8823 22.5624 45.6174 22.2603C45.3645 21.9462 44.925 21.7891 44.2989 21.7891C43.2031 21.7891 42.601 22.2845 42.4926 23.2754H40.3793C40.4636 22.2241 40.873 21.4386 41.6076 20.919C42.3541 20.3994 43.2934 20.1396 44.4253 20.1396C46.942 20.1396 48.2004 21.2936 48.2004 23.6016V29.8187H46.0509V28.6587C45.3766 29.5529 44.4133 30 43.1609 30ZM43.6847 28.4231C44.3711 28.4231 44.9311 28.2539 45.3645 27.9155C45.798 27.5651 46.0148 27.0939 46.0148 26.5017V25.5592H44.8949C43.0766 25.5592 42.1675 26.0728 42.1675 27.0999C42.1675 27.982 42.6732 28.4231 43.6847 28.4231Z"
      fill="black"
    />
    <path
      d="M52.9632 30C51.9758 30 51.1871 29.7039 50.597 29.1118C50.007 28.5197 49.712 27.6195 49.712 26.4111V20.339H51.8975V26.1574C51.8975 26.8703 52.048 27.3959 52.3491 27.7343C52.6501 28.0726 53.1197 28.2418 53.7579 28.2418C54.3841 28.2418 54.9079 28.0424 55.3294 27.6437C55.7508 27.2449 55.9616 26.689 55.9616 25.9761V20.339H58.1471V29.8187H55.9616V28.3324C55.7087 28.8279 55.3234 29.2327 54.8056 29.5469C54.2998 29.849 53.6857 30 52.9632 30Z"
      fill="black"
    />
    <path
      d="M63.9717 30C62.5869 30 61.443 29.571 60.5398 28.7131C59.6488 27.843 59.2032 26.6709 59.2032 25.1967V25.0517C59.2032 23.5775 59.6608 22.3933 60.576 21.4991C61.4911 20.5928 62.623 20.1396 63.9717 20.1396C65.1277 20.1396 66.1031 20.4296 66.8978 21.0097C67.7046 21.5776 68.1682 22.4718 68.2886 23.6923H66.1753C66.0067 22.496 65.2842 21.8978 64.0078 21.8978C63.2492 21.8978 62.6291 22.1758 62.1474 22.7316C61.6778 23.2875 61.443 24.0487 61.443 25.0154V25.1604C61.443 26.1755 61.6717 26.9549 62.1293 27.4987C62.599 28.0424 63.2432 28.3143 64.062 28.3143C64.6641 28.3143 65.1759 28.1391 65.5973 27.7887C66.0308 27.4262 66.2777 26.9247 66.3379 26.2842H68.3428C68.2585 27.3959 67.825 28.2962 67.0423 28.985C66.2596 29.6617 65.2361 30 63.9717 30Z"
      fill="black"
    />
    <path
      d="M72.5368 29.9819C71.6096 29.9819 70.8992 29.7402 70.4055 29.2568C69.9238 28.7735 69.683 28.0787 69.683 27.1724V21.9885H68.4186V20.339H69.683V18.2546H71.8685V20.339H73.9457V21.9885H71.8685V26.973C71.8685 27.7826 72.2298 28.1874 72.9523 28.1874C73.3737 28.1874 73.729 28.127 74.0179 28.0062V29.7462C73.5965 29.9033 73.1028 29.9819 72.5368 29.9819Z"
      fill="black"
    />
    <path
      d="M75.1404 29.8187V20.339H77.3259V29.8187H75.1404ZM77.1272 18.4177C76.8744 18.6594 76.5673 18.7802 76.206 18.7802C75.8448 18.7802 75.5377 18.6594 75.2849 18.4177C75.032 18.176 74.9056 17.88 74.9056 17.5295C74.9056 17.167 75.032 16.8649 75.2849 16.6233C75.5377 16.3816 75.8448 16.2607 76.206 16.2607C76.5673 16.2607 76.8744 16.3816 77.1272 16.6233C77.3801 16.8649 77.5065 17.167 77.5065 17.5295C77.5065 17.88 77.3801 18.176 77.1272 18.4177Z"
      fill="black"
    />
    <path
      d="M83.3133 28.2962C84.1201 28.2962 84.7523 28.0183 85.2099 27.4624C85.6795 26.8945 85.9143 26.1211 85.9143 25.1423V25.0154C85.9143 24.0367 85.6795 23.2693 85.2099 22.7135C84.7523 22.1455 84.1201 21.8616 83.3133 21.8616C82.4945 21.8616 81.8503 22.1395 81.3807 22.6954C80.9231 23.2512 80.6943 24.0185 80.6943 24.9973V25.1423C80.6943 26.1211 80.9231 26.8945 81.3807 27.4624C81.8503 28.0183 82.4945 28.2962 83.3133 28.2962ZM86.7813 28.6587C85.8662 29.5529 84.7041 30 83.2953 30C81.8864 30 80.7244 29.5529 79.8092 28.6587C78.9061 27.7645 78.4545 26.6045 78.4545 25.1786V25.0336C78.4545 23.5835 78.9121 22.4053 79.8273 21.4991C80.7425 20.5928 81.9045 20.1396 83.3133 20.1396C84.7102 20.1396 85.8662 20.5867 86.7813 21.4809C87.6965 22.3751 88.1541 23.5412 88.1541 24.9792V25.1242C88.1541 26.5863 87.6965 27.7645 86.7813 28.6587Z"
      fill="black"
    />
    <path
      d="M89.2257 29.8187V20.339H91.4112V21.8434C91.6521 21.348 92.0374 20.9432 92.5672 20.629C93.0971 20.3028 93.7293 20.1396 94.4638 20.1396C95.4632 20.1396 96.252 20.4478 96.83 21.064C97.408 21.6682 97.697 22.5987 97.697 23.8554V29.8187H95.5114V24.0548C95.5114 23.3418 95.3609 22.8162 95.0599 22.4778C94.7588 22.1395 94.2832 21.9703 93.6329 21.9703C92.9827 21.9703 92.4468 22.1697 92.0254 22.5685C91.6159 22.9552 91.4112 23.511 91.4112 24.236V29.8187H89.2257Z"
      fill="black"
    />
    <rect width="30" height="30" fill="#FF4016" />
  </svg>
);

export const Info = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#C7C7C7" />
    <path
      d="M13.101 23.196V12.075H16.368V23.196H13.101ZM16.071 9.213C15.693 9.573 15.234 9.753 14.694 9.753C14.154 9.753 13.695 9.573 13.317 9.213C12.939 8.853 12.75 8.412 12.75 7.89C12.75 7.35 12.939 6.9 13.317 6.54C13.695 6.18 14.154 6 14.694 6C15.234 6 15.693 6.18 16.071 6.54C16.449 6.9 16.638 7.35 16.638 7.89C16.638 8.412 16.449 8.853 16.071 9.213Z"
      fill="white"
    />
  </svg>
);

export const Auth = (props) => (
  <svg
    {...props}
    viewBox="0 0 680 1014"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect {...props} fill="#FF4016" />
    <path d="M0 0V1014H680V0H0Z" fill="#2A5780" />
    <path d="M679.644 0H261.155V1014H679.644V0Z" fill="#FF4016" />
    <path
      d="M259.62 1010.6L436.489 1014H680V940.006L261.421 555.309L261.061 712.359L267.905 752.754L259.62 1010.6Z"
      fill="black"
    />
    <path
      d="M333.222 395.569V981.947H477V331.464L333.222 395.569Z"
      fill="#EDECD8"
    />
    <path
      d="M90.2622 555.833H261.154V184.397L90.2622 217.959V555.833Z"
      fill="#B5D0BE"
    />
    <path d="M165.38 725.122H261.467V555.309H165.38V725.122Z" fill="#EDECD8" />
    <path
      d="M160.19 654.631H198.721V423.851L160.19 420.08V654.631Z"
      fill="black"
    />
    <path d="M242.959 641.811H204.428V714.213H242.959V641.811Z" fill="black" />
    <path
      d="M0 437.803V1014H576.894V961.207L476.999 943.107V869.951L394.229 857.13L392.088 856.752V793.401H391.731L311.815 780.957V714.589H311.102L206.569 709.31V641.81H161.259V420.08L0 437.803Z"
      fill="#FF4016"
    />
    <path
      d="M357.838 799.811V863.163L391.374 856.752L392.088 856.375V793.401H391.731L357.838 799.811Z"
      fill="black"
    />
    <path
      d="M440.964 874.099V946.123L475.214 942.73L476.998 942.353V870.705L440.964 874.099Z"
      fill="black"
    />
    <path
      d="M539.79 969.126V1014H576.893V961.584L539.79 969.126Z"
      fill="black"
    />
    <path
      d="M274.402 714.387V781.726H315.978V714.387H315.186H274.402Z"
      fill="black"
    />
    <path
      d="M333.222 395.57L477 402.734V331.464L333.222 395.57Z"
      fill="black"
    />
    <path
      d="M580.462 309.592L679.644 265.473V428.376L580.462 472.496V309.592Z"
      fill="#EDECD8"
    />
    <path
      d="M580.462 309.592L679.644 265.473V428.376L580.462 309.592Z"
      fill="black"
    />
  </svg>
);

export const Close = ({ size, ...props }) => (
  <svg
    {...props}
    width={size ? size : 28}
    height={size ? size : 28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 2.8L25.2 0L14 11.2L2.8 0L0 2.8L11.2 14L0 25.2L2.8 28L14 16.8L25.2 28L28 25.2L16.8 14L28 2.8Z"
      fill="#C7C7C7"
    />
  </svg>
);

export const SettingsImage = (props) => (
  <svg
    {...props}
    viewBox="0 0 560 281"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="559.002"
      width="279.602"
      height="559.002"
      transform="rotate(90 559.002 0)"
      fill="#EDECD8"
    />
    <path
      d="M466.965 94.7933V187.197H374.726L466.965 94.7933Z"
      fill="#B5D0BE"
    />
    <path d="M140.948 92.404V0L48.7085 0L140.948 92.404Z" fill="#B5D0BE" />
    <path d="M560 187.198V280.398H466.966L560 187.198Z" fill="black" />
    <path d="M466.965 0.79747V93.998H560L466.965 0.79747Z" fill="black" />
    <path d="M187.862 187.198V280.398H280.897L187.862 187.198Z" fill="black" />
    <path
      d="M513.88 187.199C488.19 187.199 467.363 166.335 467.363 140.598C467.363 114.862 488.189 93.998 513.88 93.998C513.88 93.998 513.88 114.862 513.88 140.598V187.199Z"
      fill="#FF4016"
    />
    <path
      d="M420.846 280.398C395.155 280.398 374.328 259.534 374.328 233.798C374.328 208.061 395.155 187.197 420.845 187.197C420.845 187.197 420.846 208.061 420.846 233.798V280.398Z"
      fill="black"
    />
    <path
      d="M94.8284 92.8027C94.8284 67.0661 74.0019 46.2022 48.3112 46.2022C22.6204 46.2022 1.79395 67.0659 1.79395 92.8025C1.79395 92.8025 22.6204 92.8027 48.3112 92.8027H94.8284Z"
      fill="#FF4016"
    />
    <path
      d="M187.862 93.2006C162.172 93.2006 141.345 72.3369 141.345 46.6003C141.345 20.8637 162.171 0 187.862 0C187.862 0 187.862 20.8637 187.862 46.6003V93.2006Z"
      fill="black"
    />
    <path
      d="M187.862 186.402C162.172 186.402 141.345 165.538 141.345 139.801C141.345 114.065 162.171 93.2012 187.862 93.2012C187.862 93.2012 187.862 114.065 187.862 139.801V186.402Z"
      fill="#B5D0BE"
    />
    <rect
      x="373.136"
      y="0.796875"
      width="92.404"
      height="92.2393"
      rx="46.1196"
      transform="rotate(90 373.136 0.796875)"
      fill="black"
    />
    <rect
      x="93.2378"
      y="187.994"
      width="92.404"
      height="92.2393"
      rx="46.1196"
      transform="rotate(90 93.2378 187.994)"
      fill="#FF4016"
    />
    <rect
      x="466.17"
      y="46.2031"
      width="46.9986"
      height="46.9148"
      rx="23.4574"
      transform="rotate(90 466.17 46.2031)"
      fill="black"
    />
    <rect
      x="235.572"
      y="139.402"
      width="46.9986"
      height="46.9148"
      rx="23.4574"
      transform="rotate(90 235.572 139.402)"
      fill="#FF4016"
    />
    <rect
      x="93.2378"
      y="94.793"
      width="46.9986"
      height="46.9148"
      rx="23.4574"
      transform="rotate(90 93.2378 94.793)"
      fill="black"
    />
    <rect
      x="373.931"
      y="187.994"
      width="91.6074"
      height="91.4441"
      transform="rotate(90 373.931 187.994)"
      fill="#B5D0BE"
    />
    <rect
      x="140.948"
      y="187.994"
      width="91.6074"
      height="45.3245"
      transform="rotate(90 140.948 187.994)"
      fill="black"
    />
    <rect
      x="418.46"
      width="91.6074"
      height="45.3245"
      transform="rotate(90 418.46 0)"
      fill="#FF4016"
    />
    <rect x="187.862" width="91.4441" height="45.4054" fill="#FF4016" />
    <path
      d="M373.136 186.4V94.793H281.692C281.692 145.296 322.633 186.4 373.136 186.4Z"
      fill="#FF4016"
    />
    <rect
      x="280.896"
      y="93.998"
      width="45.4054"
      height="45.3245"
      transform="rotate(90 280.896 93.998)"
      fill="#B5D0BE"
    />
    <rect
      x="513.88"
      y="187.994"
      width="45.4054"
      height="46.9148"
      transform="rotate(90 513.88 187.994)"
      fill="#B5D0BE"
    />
    <rect
      x="140.948"
      y="142.588"
      width="45.4054"
      height="45.3245"
      transform="rotate(90 140.948 142.588)"
      fill="#B5D0BE"
    />
  </svg>
);

export const Trash = (props) => (
  <svg
    {...props}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 6H2.25C1.836 6 1.5 5.66475 1.5 5.25C1.5 4.836 1.836 4.5 2.25 4.5H17.25C17.664 4.5 18 4.836 18 5.25C18 5.66475 17.664 6 17.25 6ZM16.5 21C16.5 21.828 15.828 22.5 15 22.5H4.5C3.672 22.5 3 21.828 3 21V7.5C4.03125 7.5 15.6562 7.5 16.5 7.5V21ZM7.5 2.25C7.5 1.83525 7.836 1.5 8.25 1.5H11.25C11.664 1.5 12 1.83525 12 2.25V3C11.2732 3 7.5 3 7.5 3V2.25ZM18 3H13.5V1.5C13.5 0.672 12.828 0 12 0H7.5C6.672 0 6 0.672 6 1.5V3H1.5C0.672 3 0 3.672 0 4.5V6C0 6.828 0.672 7.5 1.5 7.5V21C1.5 22.6567 2.84325 24 4.5 24H15C16.6567 24 18 22.6567 18 21V7.5C18.828 7.5 19.5 6.828 19.5 6V4.5C19.5 3.672 18.828 3 18 3ZM9.75 21C10.164 21 10.5 20.6647 10.5 20.25V11.25C10.5 10.836 10.164 10.5 9.75 10.5C9.336 10.5 9 10.836 9 11.25V20.25C9 20.6647 9.336 21 9.75 21ZM6 21C6.414 21 6.75 20.6647 6.75 20.25V11.25C6.75 10.836 6.414 10.5 6 10.5C5.586 10.5 5.25 10.836 5.25 11.25V20.25C5.25 20.6647 5.586 21 6 21ZM13.5 21C13.914 21 14.25 20.6647 14.25 20.25V11.25C14.25 10.836 13.914 10.5 13.5 10.5C13.086 10.5 12.75 10.836 12.75 11.25V20.25C12.75 20.6647 13.086 21 13.5 21Z"
      fill="#C7C7C7"
    />
  </svg>
);

export const Edit = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9754 5.27459L25.7254 9.02459C26.0915 9.3907 26.0915 9.9843 25.7254 10.3504L16.3504 19.7254C16.1746 19.9012 15.9361 20 15.6875 20H11.9375C11.4197 20 11 19.5803 11 19.0625V15.3125C11 15.0639 11.0988 14.8254 11.2746 14.6496L20.6496 5.27459C21.0157 4.90847 21.6093 4.90847 21.9754 5.27459ZM12.5 15.3125V18.5H15.6875L24.3389 9.65599L21.3389 6.65599L12.5 15.3125Z"
      fill="#C7C7C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2857 23.5714C25.2857 24.5031 24.5031 25.2857 23.5714 25.2857L6.37286 25.314C5.44114 25.314 4.686 24.5589 4.686 23.6271L4.71429 6.42857C4.71429 5.49686 5.49686 4.71429 6.42857 4.71429H15.8571V3H6.42857C4.566 3 3 4.90371 3 6.76629V23.6271C3 25.4897 4.51029 27 6.37286 27H23.2337C25.0963 27 27 25.434 27 23.5714V14.1429H25.2857V23.5714Z"
      fill="#C7C7C7"
    />
  </svg>
);

export const Multi = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.25 9C21.4513 9 20.75 7.54875 20.75 6.7695C20.75 6.7695 20.75 5.98125 20.75 4.52325V4.52175L24.5 9H22.25ZM24.5 21C24.5 21.7642 23.7875 22.5 23 22.5H11.75C10.9625 22.5 10.25 21.7642 10.25 21V6C10.25 5.23575 10.9625 4.5 11.75 4.5H19.25C19.238 6.228 19.25 7.51875 19.25 7.51875C19.25 9.07725 20.6525 10.5 22.25 10.5C22.25 10.5 23.0473 10.5 24.5 10.5V21ZM19.25 25.5H8C7.2125 25.5 6.5 24.7642 6.5 24V9C6.5 8.23575 7.14125 7.524 7.92875 7.524L8.75 7.5V21C8.75 22.5443 10.163 24 11.75 24H20.75C20.75 24.7642 20.0367 25.5 19.25 25.5ZM20.75 3C20.657 3 11.75 3 11.75 3C10.163 3 8.75 4.45575 8.75 6L7.88375 6.02025C6.29675 6.02025 5 7.45575 5 9V24C5 25.5443 6.413 27 8 27H19.25C20.837 27 22.25 25.5443 22.25 24H23C24.587 24 26 22.5443 26 21V9.01725L20.75 3Z"
      fill="#C7C7C7"
    />
  </svg>
);

export const SuccessIcon = ({ className }) => <div>></div>;
export const Camera = (props) => (
  <svg
    {...props}
    width="60"
    height="49"
    viewBox="0 0 60 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1619 7.74575C16.3547 7.47762 16.569 7.14939 16.8421 6.7068C16.9418 6.54529 17.2134 6.09719 17.4159 5.76302L17.4167 5.76169L17.4187 5.75836C17.5259 5.58151 17.6133 5.4373 17.6449 5.38568C19.9016 1.69428 21.542 0 24.5 0H38.1111V5.44444H24.5C24.2639 5.44444 23.5214 6.21136 22.2901 8.22543C22.2648 8.26682 22.1895 8.39101 22.0933 8.54974L22.0922 8.55155C21.8879 8.88867 21.59 9.38025 21.4753 9.56614C21.1406 10.1085 20.8624 10.5347 20.5817 10.925C19.3614 12.6213 18.1418 13.6111 16.3333 13.6111H8.16667C6.66322 13.6111 5.44444 14.8299 5.44444 16.3333V40.8333C5.44444 42.3368 6.66322 43.5556 8.16667 43.5556H51.7222C53.2257 43.5556 54.4444 42.3368 54.4444 40.8333V24.5H59.8889V40.8333C59.8889 45.3437 56.2325 49 51.7222 49H8.16667C3.65634 49 0 45.3437 0 40.8333V16.3333C0 11.823 3.65634 8.16667 8.16667 8.16667H15.833C15.9236 8.06306 16.0347 7.92256 16.1619 7.74575ZM43.5555 13.6111V8.16667H49V2.72238H54.4444V8.16667H59.8889V13.6111H54.4444V19.0557H49V13.6111H43.5555ZM29.9444 40.8332C22.4272 40.8332 16.3333 34.7393 16.3333 27.2221C16.3333 19.7049 22.4272 13.611 29.9444 13.611C37.4616 13.611 43.5555 19.7049 43.5555 27.2221C43.5555 34.7393 37.4616 40.8332 29.9444 40.8332ZM29.9444 35.3891C34.4547 35.3891 38.1111 31.7327 38.1111 27.2224C38.1111 22.7121 34.4547 19.0557 29.9444 19.0557C25.4341 19.0557 21.7777 22.7121 21.7777 27.2224C21.7777 31.7327 25.4341 35.3891 29.9444 35.3891Z"
      fill="white"
    />
  </svg>
);

export const CopyIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8333 6C14.3008 6 13.8333 5.0325 13.8333 4.513C13.8333 4.513 13.8333 3.9875 13.8333 3.0155V3.0145L16.3333 6H14.8333ZM16.3333 14C16.3333 14.5095 15.8583 15 15.3333 15H7.83325C7.30825 15 6.83325 14.5095 6.83325 14V4C6.83325 3.4905 7.30825 3 7.83325 3H12.8333C12.8253 4.152 12.8333 5.0125 12.8333 5.0125C12.8333 6.0515 13.7683 7 14.8333 7C14.8333 7 15.3648 7 16.3333 7V14ZM12.8333 17H5.33325C4.80825 17 4.33325 16.5095 4.33325 16V6C4.33325 5.4905 4.76075 5.016 5.28575 5.016L5.83325 5V14C5.83325 15.0295 6.77525 16 7.83325 16H13.8333C13.8333 16.5095 13.3578 17 12.8333 17ZM13.8333 2C13.7713 2 7.83325 2 7.83325 2C6.77525 2 5.83325 2.9705 5.83325 4L5.25575 4.0135C4.19775 4.0135 3.33325 4.9705 3.33325 6V16C3.33325 17.0295 4.27525 18 5.33325 18H12.8333C13.8913 18 14.8333 17.0295 14.8333 16H15.3333C16.3913 16 17.3333 15.0295 17.3333 14V6.0115L13.8333 2Z"
      fill="#C7C7C7"
    />
  </svg>
);
