import React, { memo } from "react";
import Modal from "./index";
import styled from "styled-components";
import { colors } from "components/variable";
import { rem } from "utils/common";
import { Icon } from "components/icon";
import Button from "components/button";
import Preloader from "components/preloader";
import { WelcomeTitle } from "components/styled";

import Image from "static/images/anim.png";

const Wrap = styled.div`
  padding: ${rem(35)} ${rem(20)};
  max-width: ${rem(900)};
`;

const CloseBlock = styled.div`
  position: absolute;
  top: ${rem(45)};
  right: ${rem(70)};
`;

const SimpleModal = memo((props) => {
  const { visible, children, onCancel, mode, loading } = props;
  const customStyles = {
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background:
        mode === "cool"
          ? "url(" + Image + ") no-repeat top center rgba(0, 0, 0, 0.8)"
          : "rgba(0, 0, 0, 0.8)",
      backgroundSize: "cover",
      backdropFilter: "blur(44px)",
      zIndex: 9999,
    },
  };
  return (
    <Modal visible={visible} style={customStyles}>
      <CloseBlock>
        <Icon
          component="Close"
          onClick={onCancel}
          className="cursor-pointer pathHover"
        />
      </CloseBlock>
      <Wrap>{children}</Wrap>
    </Modal>
  );
});

SimpleModal.defaultProps = {
  children: null,
  onCancel: function () {},
};

export default SimpleModal;
