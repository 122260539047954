import Axios from "axios";
import { toastr } from "react-redux-toastr";
import environment from "environment";
import events from "utils/events";
import { push } from "connected-react-router";
import { store } from "store";
import { logout } from "store";
import { AUTH_TOKEN } from "utils/auth";

const defaultMutationHeaders = {
  "Content-Type": "application/json",
};

function createAxiosAdapter() {
  const axios = Axios.create({
    baseURL: environment.apiUrl,
  });

  axios.interceptors.request.use((config) => {
    const { headers } = config;

    const token = localStorage.getItem(AUTH_TOKEN);

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response.data,
    (err) => {
      if (!err || !err.response) return err;

      const error = err.response;
      const errorMessage = error.data ? error.data.error : "Unknown";

      if (error.status === 401) {
        setTimeout(() => {
          store.dispatch(push("/auth/login"));
          setTimeout(() => {
            store.dispatch(logout());
          });
        }, 0);
        return;
      } else {
        console.error("Error");
      }
      return Promise.reject(error.data);
    }
  );

  return {
    get(url, params, config) {
      return axios.get(url, { params, ...config });
    },
    post(url, body, headers = defaultMutationHeaders, config) {
      return axios.post(url, body, { headers, ...config });
    },
    put(url, body, headers = defaultMutationHeaders, config) {
      return axios.put(url, body, { headers, ...config });
    },
    patch(url, body, headers = defaultMutationHeaders, config) {
      return axios.patch(url, body, { headers, ...config });
    },
    delete(url, body, headers = defaultMutationHeaders, config) {
      return axios.delete(url, { data: body }, { headers, ...config });
    },
  };
}
export default createAxiosAdapter();
