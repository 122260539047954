const dev = process.env.NODE_ENV !== "production";
const prod = process.env.NODE_ENV === "production";

const defaultHost = {
  apiUrl: dev
    ? "https://staging.anonymauction.com"
    : `https://${window.location.host}`,
  url: dev
    ? "https://staging.anonymauction.com"
    : `https://${window.location.host}`,
  host: "staging.anonymauction.com",
  socketUrl: dev
    ? "wss://staging.anonymauction.com/api/ws"
    : `wss://${window.location.host}/api/ws`,
  prod: prod,
};

const hosts = {
  localhost: defaultHost,
};

const currentHost = hosts[window.location.hostname]
  ? hosts[window.location.hostname]
  : defaultHost;

export default currentHost;
