export const rem = (px) => +px / 16 + "rem";
export const remToPx = (rem) => +rem * 16 + "px";

export const parseJSON = (data) => {
  let value = false;
  try {
    value = JSON.parse(data);
  } catch (err) {}
  return value;
};
