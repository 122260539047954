import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { rem } from "utils/common";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { registerStart } from "store";

import Layout from "components/layout/auth";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

const TextBottom = styled.div`
  margin-top: ${rem(100)};
`;

function Register() {
  const dispatch = useDispatch();
  const onRegister = ({ confirmPassword, ...values }) => {
    dispatch(registerStart(values));
  };
  return (
    <Layout
      title="Registration"
      bottomText
      linkText="Log in"
      href="/auth/login"
    >
      <Formik
        initialValues={{
          name: "",
          keyCode: "",
          telegramAccount: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("required"),
          telegramAccount: Yup.string().min(5, "Seems a bit short..."),
          keyCode: Yup.string().required("required"),
          password: Yup.string()
            .required()
            .min(2, "Seems a bit short...")
            .max(100, "Very long password."),
          confirmPassword: Yup.string()
            .required()
            .test("passwords-match", "Passwords are different", function (
              value
            ) {
              return this.parent.password === value;
            }),
          email: Yup.string().email("wrong email").required("required"),
        })}
        onSubmit={(values) => {
          onRegister(values);
        }}
      >
        {({ handleSubmit, isValid, error }) => {
          return (
            <FormWrap onSubmit={handleSubmit}>
              <InputWithLabel
                label="User Name"
                name="name"
                type="text"
                mode="sideError"
              />
              <InputWithLabel label="Key Code" name="keyCode" type="text" />
              <InputWithLabel
                label="Telegram ( @Username )"
                name="telegramAccount"
                type="text"
              />
              <InputWithLabel label="Your email" name="email" type="email" />
              <InputWithLabel
                label="Your password"
                name="password"
                type="password"
                secureTextEntry
              />
              <InputWithLabel
                label="Repeat password"
                name="confirmPassword"
                type="password"
                secureTextEntry
              />
              <Button
                color={isValid && !error ? "red" : "grey"}
                disabled={!isValid || error}
                className="mt-40"
              >
                Registration
              </Button>
            </FormWrap>
          );
        }}
      </Formik>
    </Layout>
  );
}

export default Register;
