import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { profileActions } from "store";
import clsx from "clsx";

import LayoutSettings from "components/layout/settings";

import Preloader from "components/preloader";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

const validationObj = Yup.object({
  telegramAccount: Yup.string().min(5, "Seems a bit short..."),
  passwordOld: Yup.string().required(),
  password: Yup.string()
    .required()
    .min(2, "Seems a bit short...")
    .max(100, "Very long password."),
  confirmPassword: Yup.string()
    .required()
    .test("passwords-match", "Passwords are different", function (value) {
      return this.parent.password === value;
    }),
});

function Settings() {
  const [changePassword, setChangePassword] = useState(false);
  const [validation, setValidation] = useState({});

  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => {
    return {
      user: state.profile.user,
      loading: state.profile.loading,
    };
  }, shallowEqual);

  useEffect(() => {
    setValidation(
      changePassword
        ? validationObj
        : Yup.object({
            telegramAccount: Yup.string()
              .required("required")
              .min(5, "Seems a bit short..."),
          })
    );
  }, [changePassword]);

  const onUpdate = ({ ...values }) => {
    dispatch(
      profileActions.updateUser({
        ...values,
        withPassword: changePassword,
      })
    );
  };
  const content = loading ? (
    <Preloader />
  ) : (
    <Formik
      initialValues={{
        name: user.name,
        telegramAccount: user.telegram,
        email: user.email,
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validation}
      onSubmit={onUpdate}
    >
      {({ handleSubmit, isValid, error }) => {
        return (
          <FormWrap theme="center" onSubmit={handleSubmit}>
            <InputWithLabel
              label="User Name"
              name="name"
              type="text"
              mode="sideError"
              className="input-disable"
              theme="inputDisable"
              value={user.name}
            />
            <InputWithLabel
              label="Telegram ( @Username )"
              name="telegramAccount"
              type="text"
            />
            <InputWithLabel
              label="Your email"
              name="email"
              type="email"
              className="input-disable"
              theme="inputDisable"
              value={user.email}
            />
            {changePassword && (
              <>
                <InputWithLabel
                  label="Old password"
                  name="passwordOld"
                  type="password"
                  secureTextEntry
                />
                <InputWithLabel
                  label="New password"
                  name="password"
                  type="password"
                  secureTextEntry
                />
                <InputWithLabel
                  label="Repeat new password"
                  name="confirmPassword"
                  type="password"
                  secureTextEntry
                />
              </>
            )}
            {changePassword ? (
              <SmallText className="mb-24 mt-2 text-right cursor-pointer">
                <Link to="/auth/reset-password">forgot password</Link>
              </SmallText>
            ) : (
              <SmallText
                className="mb-24 mt-2 text-right cursor-pointer"
                onClick={() => setChangePassword(!changePassword)}
              >
                change password
              </SmallText>
            )}

            <div
              className={clsx(
                "d-flex",
                !changePassword
                  ? "justify-content-end"
                  : "justify-content-between"
              )}
            >
              {changePassword && (
                <Button
                  type="button"
                  className="medium"
                  mode="small"
                  color="lightYellow"
                  textColor="green"
                  onClick={() => setChangePassword(false)}
                >
                  Cancel
                </Button>
              )}
              <Button
                mode="small"
                type="submit"
                color={isValid && !error ? "red" : "grey"}
                disabled={!isValid || error}
              >
                Save
              </Button>
            </div>
          </FormWrap>
        );
      }}
    </Formik>
  );

  return <LayoutSettings title="Account Settings">{content}</LayoutSettings>;
}

export default Settings;
