import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Header from "containers/Header";
import { lotsActions } from "store";

import ReactPaginate from "react-paginate";
import { PaginationWrap } from "components/styled";
import Dropdown from "react-dropdown";
import ConfirmModal from "components/modal/confirm";
import Preloader from "components/preloader";
import Lot from "containers/UserPanel/components/lot";
import { MainWrap, SmallTitle, Text, Row } from "components/styled";

const options = [
  { label: "All lots", value: "" },
  { label: "Active", value: "active" },
  { label: "Coming soon", value: "comingSoon" },
  { label: "Sold", value: "sold" },
];

function UserPanel() {
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const { lots, currentPage, pageCount, modalDelete, loading } = useSelector(
    (state) => {
      return {
        lots: state.lots.lots,
        pageCount: state.lots.pageCount,
        currentPage: state.lots.currentPage,
        modalDelete: state.lots.modalDelete,
        loading: state.lots.loading,
      };
    },
    shallowEqual
  );

  const [tab, setTab] = useState(options[0].value);
  const changeTab = ({ value }) => {
    setTab(value);
  };

  useEffect(() => {
    dispatch(lotsActions.getLotsStart({ status: tab, page: 0 }));
  }, [tab]);

  const getLots = ({ selected: page }) => {
    dispatch(lotsActions.getLotsStart({ status: tab, page }));
  };

  const onDelete = (id) => () => {
    setDeleteId(id);
    dispatch(lotsActions.deleteLotModal());
  };

  const deleteLot = () => () => {
    dispatch(lotsActions.deleteLotStart({ id: deleteId }));
  };

  const content = loading ? (
    <Preloader />
  ) : Object.values(lots).length > 0 ? (
    Object.values(lots).map((item, idx) => (
      <Lot key={idx} data={item} onDelete={onDelete(item.id)} />
    ))
  ) : (
    <SmallTitle className="text-center" color="grey">
      No {tab} Lots
    </SmallTitle>
  );
  return (
    <MainWrap className="container-xl">
      <Header theme="lotsPage" />
      <Row className="justify-content-end mb-2">
        <Dropdown
          options={options}
          onChange={changeTab}
          value={options[0].values}
          placeholder={options[0].label}
        />
      </Row>
      <div>{content}</div>
      {pageCount > 1 && (
        <PaginationWrap className="mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakClassName={"break-me"}
            pageCount={pageCount}
            forcePage={currentPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={getLots}
            containerClassName={"pagination mb-4"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </PaginationWrap>
      )}
      <ConfirmModal
        text="Are you sure that you want to delete Lot?"
        visible={modalDelete}
        onSave={deleteLot()}
        onCancel={() => dispatch(lotsActions.deleteLotModal())}
      />
    </MainWrap>
  );
}

export default UserPanel;
