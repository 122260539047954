import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isAuth } from "utils/auth";

export default ({ component: Component, children, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() ? (
        children ? (
          children
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: `/auth/login`,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export const AdminRoute = ({ component: Component, children, ...rest }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    return {
      user: state.profile.user,
    };
  }, shallowEqual);
  return (
    <Route
      {...rest}
      render={(props) =>
        user.id &&
        (user.isAdmin ? (
          children ? (
            children
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/lots`,
              state: { from: props.location },
            }}
          />
        ))
      }
    />
  );
};
