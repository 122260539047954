import React from "react";
import { Link } from "react-router-dom";
import { goBack } from "utils/history";

import Instruction from "components/instruction";
import Header from "containers/Header";
import styled from "styled-components";
import { rem } from "utils/common";
import { colors, device } from "components/variable";

import { Icon } from "components/icon";
import {
  FormWrap,
  MainWrap,
  SmallText,
  ContentWrap,
  Text,
  ContentTopLink,
  SmallTitle,
} from "components/styled";

const AbsolutePart = styled.div`
  justify-content: flex-end;
  cursor: pointer;
  padding-right: ${rem(15)};
  position: relative;
  bottom: ${rem(-30)};
  @media ${device.tablet} {
    padding-right: ${rem(0)};

    position: absolute;
    bottom: ${rem(-100)};
    right: 0;
    margin-bottom: ${rem(15)};
  }
`;

function LayoutSimple({ children, mode, instruction }) {
  return (
    <MainWrap className="container-xl">
      <Header />
      <ContentWrap mode={mode} className="mt-2">
        <ContentTopLink>
          <Text onClick={goBack} className="cursor-pointer">
            {"<- Back"}
          </Text>
        </ContentTopLink>
        <>
          {children}
          {mode === "withInfo" && (
            <AbsolutePart>
              <Instruction instruction={instruction} />
            </AbsolutePart>
          )}
        </>
      </ContentWrap>
    </MainWrap>
  );
}

export default LayoutSimple;
