import React, { useState, useEffect } from "react";
import { goBack } from "utils/history";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import Header from "containers/Header";
import styled from "styled-components";
import { rem } from "utils/common";
import { colors, device, maxW } from "components/variable";

import Preloader from "components/preloader";
import { Icon } from "components/icon";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import {
  WelcomeTitle,
  FormWrap,
  MainWrap,
  SmallText,
  ContentWrap,
  Text,
  ContentTopLink,
} from "components/styled";

const LeftSide = styled.div`
  background: ${(props) =>
    props.mode === "second" ? colors.lightGreen : colors.lightYellow};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptopS} {
    padding: 0;
    min-height: ${rem(690)};
  }
`;

const ItemTitle = styled(WelcomeTitle)`
  padding: ${rem(20)} 0;
  text-align: center !important;
  @media ${maxW.laptopXS} {
    font-size: ${rem(80)};
  }
  @media ${device.laptopS} {
    text-align: left !important;
    padding: ${rem(65)} 0;
    padding-left: 2%;
  }
  @media ${device.laptopM} {
    text-align: left !important;
    padding: ${rem(65)} 0;
    padding-left: 28%;
  }
`;

const RightSide = styled.div`
  padding-bottom: ${rem(90)};
  @media ${maxW.laptopS} {
    background: ${colors.white};
  }
  @media ${device.laptopS} {
    padding-bottom: ${rem(24)};
  }
`;

const validationObj = Yup.object({
  password: Yup.string()
    .required()
    .min(2, "Seems a bit short...")
    .max(100, "Very long password."),
  confirmPassword: Yup.string()
    .required()
    .test("passwords-match", "Passwords are different", function (value) {
      return this.parent.password === value;
    }),
});

function LayoutSettings({ title, children, mode }) {
  return (
    <MainWrap className="container-xl">
      <Header />
      <ContentWrap className="mt-2">
        <ContentTopLink>
          <Text onClick={goBack} className="cursor-pointer">
            {"<- Back"}
          </Text>
        </ContentTopLink>
        <div
          className={clsx(
            "row flex-lg-row mb-5",
            mode === "second" ? "align-items-start" : "align-items-center"
          )}
        >
          <LeftSide mode={mode} className="col-12 col-lg-5 col-xl-4">
            <ItemTitle>{title}</ItemTitle>
            <Icon
              component="SettingsImage"
              className="w100 d-none d-lg-block"
            />
          </LeftSide>
          <RightSide className="col-12 col-lg-7 col-xl-8 pt-4">
            {children}
          </RightSide>
        </div>
      </ContentWrap>
    </MainWrap>
  );
}

export default LayoutSettings;
