import styled, { css } from "styled-components";
import { rem } from "utils/common";
import { device, maxW } from "components/variable";

export const HeaderWrap = styled.div`
  padding: ${rem(55)} 0;
  padding-bottom: ${(props) =>
    props.theme === "lotsPage" ? rem(20) : rem(55)};
`;

export const HeaderItem = styled.li`
  padding: 0 ${rem(15)};
  font-weight: 500;
  font-size: ${rem(60)};
  line-height: ${rem(80)};
  @media ${device.laptopS} {
    font-size: ${rem(16)};
    &:last-child {
      padding-right: 0;
    }
  }
  @media ${device.laptopXS} {
    padding: 0 ${rem(25)};
  }
  @media ${device.laptopM} {
    font-size: ${rem(18)};

    padding: 0 ${rem(55)};
  }
`;

export const MenuWrap = styled.ul`
  display: ${(props) => (props.open ? "flex" : "none")};
  @media ${maxW.laptopS} {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background: #fff;
    flex-direction: column;
    padding-top: ${rem(120)};
    overflow-y: scroll;
  }
  @media ${device.laptopS} {
    display: flex;
  }
`;
