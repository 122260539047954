import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import clsx from "clsx";

import Instruction from "components/instruction";
import { WelcomeTitle, WelcomeDesc, SmallText, Text } from "components/styled";
import { Icon } from "components/icon";
import { rem } from "utils/common";
import { colors, device } from "components/variable";

import Image from "static/images/auth.svg";

const Wrap = styled.div`
  min-height: 100vh;
  width: 100%;
`;
const LeftSide = styled.div`
  background: url(${Image}) ${colors.red} no-repeat center center;
  background-size: cover;
`;

const RightSideWrap = styled.div`
  min-height: 100vh;
  padding: ${rem(70)} 0;
`;

const RightSide = styled.div`
  width: 100%;
  text-align: center;
  @media ${device.tablet} {
    text-align: left;
  }
`;

function Layout({ title, description, bottomText, linkText, href, children }) {
  return (
    <Wrap className="d-flex flex-wrap ">
      <LeftSide className="col-12 col-md-4 p-0 d-md-flex justify-content-center align-items-center block-for-desktop">
        {/* <Icon component="Auth" className="w100" /> */}
      </LeftSide>
      <RightSideWrap className="col-12 col-md-8 d-flex flex-wrap align-items-center ">
        <div className="d-flex flex-wrap w100 justify-content-end align-self-start pl-5 pr-5">
          {linkText && (
            <Text color="grey" colorHover="red">
              <Link to={href}>
                {linkText + " "}
                ->
              </Link>
            </Text>
          )}
        </div>
        <div className="d-flex flex-wrap w100">
          <div className="col-12 col-md-1 col-xs-2"></div>
          <RightSide className="col-12 col-md-10">
            <WelcomeTitle className="mb-8">{title}</WelcomeTitle>
            {description && (
              <WelcomeDesc className="mb-48">{description}</WelcomeDesc>
            )}
            {children}
          </RightSide>
        </div>
        <div className="d-flex flex-wrap w100 align-self-end">
          <div className="col-12 col-md-1 col-xs-2"></div>
          <div className="col-12 col-md-10">
            <div
              className={clsx(
                "d-flex align-items-center flex-direction-end mt-4 pl-5 pr-5",
                bottomText ? "justify-content-between" : "justify-content-end"
              )}
            >
              {bottomText && (
                <SmallText className="mr-64">
                  If you don’t have key-code. Please, contact admin via{" "}
                  <a
                    href="https://telegram.im/@jz_asa"
                    target="_blank"
                    className="medium"
                  >
                    t.me/@jz_asa
                  </a>{" "}
                  or
                  <a
                    href="mailto:buy@amzselleraccounts.com"
                    target="_blank"
                    className="medium"
                  >
                    {" "}
                    buy@amzselleraccounts.com
                  </a>
                </SmallText>
              )}
              <Instruction />
            </div>
          </div>
        </div>
      </RightSideWrap>
    </Wrap>
  );
}

export default Layout;
