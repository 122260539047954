import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "components/icon";
function Logo() {
  return (
    <Link to="/lots">
      <Icon component="Logo" />
    </Link>
  );
}

export default Logo;
