import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { rem } from "utils/common";
import { colors, maxW, someStyle, device } from "components/variable";

const textColor = (props) => {
  return css`
    color: ${props.color ? colors[props.color] : colors[props.defaultColor]};
    transition: 0.3s;
    span {
      color: ${props.colorSpan && colors[props.colorSpan]};
    }
    &:hover {
      transition: 0.3s;
      color: ${props.colorHover && colors[props.colorHover]};
    }
  `;
};

export const WelcomeTitle = styled.h3`
  font-weight: 500;
  line-height: ${rem(80)};
  font-size: ${rem(64)};
  ${(props) => textColor({ defaultColor: "black", ...props })}
  text-align: center;
  @media ${device.tablet} {
    line-height: ${rem(90)};
    font-size: ${rem(75)};
    text-align: left;
    letter-spacing: ${rem(-7)};
  }
  @media ${device.laptopS} {
    line-height: ${rem(130)};
    font-size: ${rem(126)};
  }
`;

export const WelcomeDesc = styled.h4`
  line-height: ${rem(50)};
  font-size: ${rem(36)};
  font-weight: normal;
  ${(props) => textColor({ defaultColor: "black", ...props })}
`;

export const SmallTitle = styled.h5`
  line-height: ${rem(26)};
  font-size: ${rem(24)};
  font-weight: 600;
  ${(props) => textColor({ defaultColor: "black", ...props })}
`;

export const Text = styled.p`
  line-height: ${rem(20)};
  font-size: ${rem(18)};
  ${(props) => textColor({ defaultColor: "darkGrey", ...props })}
`;

export const SmallText = styled.p`
  line-height: ${rem(20)};
  font-size: ${rem(14)};
  ${(props) => textColor({ defaultColor: "grey", ...props })}
`;

export const LinkStyled = styled(Link)`
  font-size: ${rem(60)};
  line-height: ${rem(80)};
  color: ${colors.black};
  border-radius: ${rem(339)};
  &:hover {
    color: ${colors.green};
    background: ${colors.white};
  }
  @media ${device.laptopS} {
    padding: ${rem(5)} ${rem(10)};
    font-size: ${rem(18)};
  }
`;

export const MainWrap = styled.div`
  min-height: 100vh;
  background: ${colors.greyBg};
  @media ${device.tablet} {
    padding: 0 ${rem(80)};
  }
`;

export const ContentTopLink = styled.div`
  position: absolute;
  top: ${rem(-22)};
`;

export const ContentWrap = styled.div`
  position: relative;
  margin-bottom: ${rem(60)};
  background: ${(props) => props.mode === "withInfo" && colors.white};
  padding-bottom: ${(props) => props.mode === "withInfo" && rem(80)};
  @media ${device.tablet} {
    background: ${colors.white};
  }
  @media ${device.laptopS} {
    padding-bottom: ${(props) => props.mode === "withInfo" && rem(0)};
  }
`;

export const FormWrap = styled.form`
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
  @media ${device.tablet} {
    ${(props) =>
      props.theme !== "center" &&
      css`
        margin: 0;
      `}
    text-align: left;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TableWrap = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const Table = styled.div`
  display: table;
  overflow-x: scroll;
  width: 100%;
`;

export const TableRow = styled.div`
  display: table-row;
  color: ${colors.lightBlack};
  background: ${colors.white};
  box-shadow: 0px -1px 0px ${colors.lightGrey};
  &:hover {
    background: ${colors.greyBg};
  }
`;

export const TableRowHead = styled.div`
  display: table-row;
  font-weight: 500;
  line-height: ${rem(40)};
  font-size: ${rem(16)};
  background: ${colors.darkGrey};
  color: ${colors.white};
`;

export const TableCell = styled.div`
  padding: ${rem(5)} ${rem(20)};
  display: ${(props) => (props.hidden ? "none" : "table-cell")};
  min-width: ${rem(250)};
  font-size: ${rem(15)};
  line-height: ${rem(40)};
  text-align: left;
`;

export const PaginationWrap = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    li {
      border-radius: ${rem(4)};
      border: 1px solid ${colors.yellow};
      color: ${colors.darkGrey};
      background: ${colors.white};
      cursor: pointer;
      &.active {
        color: ${colors.red};
      }
      a {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const UsersInput = styled.input`
  padding: 0 ${rem(12)};
  height: ${rem(40)};
  border: ${rem(1)} solid ${colors.yellow};
  background: ${colors.white};
  width: ${rem(260)};
  position: absolute;
  right: 0;
  top: ${rem(-40)};
`;
