import React, { Component, cloneElement } from "react";
import Tooltip from "react-tooltip-lite";

class CustomTooltip extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.bodyClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.bodyClick);
  }

  toggleTip = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  bodyClick = (e) => {
    const { childrenRef, contentRef } = this.props;
    if (
      (contentRef.current && contentRef.current.contains(e.target)) ||
      (childrenRef.current && childrenRef.current.contains(e.target))
    ) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { children, content, ...props } = this.props;
    const childrenWithProps = cloneElement(children, {
      onClick: this.toggleTip,
    });
    return (
      <Tooltip {...props} isOpen={this.state.open} content={content}>
        {childrenWithProps}
      </Tooltip>
    );
  }
}

export default CustomTooltip;
