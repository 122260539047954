import moment from "moment";
import { UploadModel } from "./upload";
import { UserModel } from "./user";
export class LotModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj._id || obj.id;
    this.status = obj.status;
    this.statusLabel = obj.status.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
    this.startAt = moment(obj.startAt).format("MMM DD YYYY, h:mm a") || "";
    this.endAt = moment(obj.endAt).format("MMM DD YYYY, h:mm a") || "";
    this.startAtSimple = moment(obj.startAt) || "";
    this.endAtSimple = moment(obj.endAt) || "";
    this.estimate = obj.estimate;
    this.dayEstimate = obj.estimate / 86400000;
    this.startPrice = Math.floor(obj.startPrice);
    this.currentPrice = Math.floor(obj.currentPrice);
    this.description = obj.description || "";
    this.image = null;

    if (typeof obj.image === "string") {
      this.image = obj.image || null;
      this.imageId = obj.imageId || null;
    }

    if (typeof obj.image === "object" || typeof obj.avatarUpload === "object") {
      const upload = obj.avatarUpload || new UploadModel(obj.image);
      this.image = upload.url || null;
      this.imageId = upload.id || null;
    }
  }
}

export class LotFullModel extends LotModel {
  constructor(obj) {
    super(obj);
    this.bidSteps =
      obj.bidSteps.map((item) => ({ value: item, label: `$${item}` })) || [];
    this.bidStepsSimple = obj.bidSteps.map((item) => item) || [];
    this.winner = new UserModel(obj.winner);
    this.instruction = obj.instruction;
  }
}
