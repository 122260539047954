import React from "react";
import styled from "styled-components";
import { colors } from "components/variable";

const Image = styled("svg")`
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
  fill: #bec2ce;
  &:hover {
    fill: ${colors.red};
  }
  &:active {
    fill: ${colors.red};
  }
`;

const Eye = () => {
  return (
    <Image
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0.039093C6.17879 0.039093 2.71351 2.12971 0.15649 5.52544C-0.0521632 5.80364 -0.0521632 6.19231 0.15649 6.47051C2.71351 9.87033 6.17879 11.9609 10 11.9609C13.8212 11.9609 17.2865 9.87033 19.8435 6.4746C20.0522 6.1964 20.0522 5.80773 19.8435 5.52953C17.2865 2.12971 13.8212 0.039093 10 0.039093ZM10.2741 10.1976C7.73755 10.3572 5.64284 8.26657 5.80239 5.72591C5.93331 3.6312 7.63118 1.93333 9.72589 1.80242C12.2625 1.64286 14.3572 3.73348 14.1976 6.27413C14.0626 8.36475 12.3647 10.0626 10.2741 10.1976ZM10.1473 8.25838C8.78081 8.3443 7.65163 7.21921 7.74164 5.85274C7.81119 4.72356 8.72763 3.81121 9.85681 3.73757C11.2233 3.65165 12.3525 4.77674 12.2625 6.14322C12.1888 7.27649 11.2724 8.18883 10.1473 8.25838Z" />
    </Image>
  );
};

export default Eye;
