import React, { useState } from "react";
import clsx from "clsx";

import { SmallTitle, Text } from "components/styled";
import { Icon } from "components/icon";
import styled, { css } from "styled-components";
import { colors, device } from "components/variable";
import { rem } from "utils/common";

const WelcomeDesc = styled.h4`
  line-height: ${rem(28)};
  font-size: ${rem(20)};
  font-weight: normal;
  color: ${colors.red};
  @media ${device.tablet} {
    line-height: ${rem(40)};
    font-size: ${rem(35)};
  }
`;

const TextWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden;
  min-width: ${rem(320)};
  max-width: ${rem(320)};
  display: ${(props) => (props.visible ? "block " : "none")};
  padding: ${rem(22)} ${rem(30)};
  padding-bottom: ${rem(60)};
  background: ${colors.white};
  border: 1px solid ${colors.yellow};
  @media ${device.tablet} {
    padding: ${rem(44)} ${rem(51)};
    padding-bottom: ${rem(110)};
    min-width: ${rem(550)};
    max-width: ${rem(550)};
    margin-right: ${rem(-42)};
    margin-bottom: ${rem(-56)};
  }
`;

const CloseWrap = styled.div`
  position: absolute;
  right: ${rem(10)};
  bottom: ${rem(14)};
  @media ${device.tablet} {
    right: ${rem(50)};
    bottom: ${rem(60)};
  }
`;

function Instruction({ instruction }) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleVisible = () => {
    setVisible(!visible);
  };
  return (
    <div className="position-relative">
      <div className="d-flex align-items-center justify-content-end">
        {instruction && (
          <SmallTitle className="mr-2" color="grey" onClick={toggleVisible}>
            Instructions
          </SmallTitle>
        )}
        <Icon
          onClick={toggleOpen}
          className="cursor-pointer circleHover"
          component="Info"
        />
      </div>

      <TextWrap visible={open}>
        <WelcomeDesc className="mb-5">
          This auction is held by ASA [Amazon Seller Accounts]{" "}
        </WelcomeDesc>
        <Text color="black" className="mb-4">
          Only registered users can participate.To register, you need to have a
          key code that you can get from the administrator
        </Text>
        <Text color="black">
          All participants' personalities are not disclosed to keep this event
          fair. From our side, we guarantee we do not manipulate this auction in
          any way.
        </Text>
        <CloseWrap>
          <Icon
            onClick={toggleOpen}
            className="cursor-pointer pathHover"
            size={16}
            component="Close"
          />
        </CloseWrap>
      </TextWrap>
      {instruction && (
        <TextWrap visible={visible}>
          <Text color="black" className="mb-5">
            {instruction}
          </Text>
          <CloseWrap>
            <Icon
              onClick={toggleVisible}
              className="cursor-pointer pathHover"
              size={16}
              component="Close"
            />
          </CloseWrap>
        </TextWrap>
      )}
    </div>
  );
}

export default Instruction;
