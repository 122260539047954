import { takeLatest, put, call, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import notifier from "simple-react-notifications";
import Api from "utils/api";
import { setToken } from "utils/auth";
import { UploadModel } from "models/upload";
import {
  loginStart,
  loginError,
  loginSuccess,
  logout,
  registerStart,
  registerError,
  registerSuccess,
  resetStart,
  resetError,
  resetSuccess,
  restoreStart,
  restoreError,
  restoreSuccess,
  confirmStart,
  confirmSuccess,
  confirmError,
  resend,
  profileActions,
  lotsActions,
  lotActions,
  usersActions,
  keysActions,
} from "./store";
import config from "_config";
import { LotModel } from "models";

function* loginWorker({ payload }) {
  const { email, password } = payload;
  try {
    const body = {
      email,
      password,
      device: "web",
    };
    const data = yield call(Api.post, "/api/users/login/email", body);
    setToken(data.token);
    const { user } = yield call(Api.get, "/api/users/profile");
    yield put({ ...profileActions.getUser(user) });
    yield put({ ...loginSuccess() });
    yield put(push("/lots"));
  } catch (e) {
    notifier.error(e.error || "Try login again");
    yield put({ ...loginError() });
  }
}

function* registerWorker({ payload }) {
  try {
    yield call(Api.post, "/api/users/register", payload);
    yield put({ ...registerSuccess(payload) });
    yield put(push(`/auth/signup/success?email=${payload.email}`));
    // yield call(loginWorker, { payload });
  } catch (e) {
    notifier.error(e.error || "Try register again");
    yield put({ ...registerError });
  }
}

function* confirmEmail({ payload }) {
  const { token } = payload;
  try {
    yield call(Api.post, "/api/users/email/confirm", { token });
    yield put({ ...confirmSuccess() });
    yield delay(1500);
    yield put(push("/auth/login"));
  } catch (e) {
    notifier.error("Email doesn't verified");
    yield put({ ...confirmError });
  }
}

function* resetPassword({ payload }) {
  try {
    yield call(Api.post, "/api/users/password/reset", payload);
    yield put({ ...resetSuccess() });
    yield put(push(`/auth/reset-password/success?email=${payload.email}`));
  } catch (e) {
    notifier.error(e.error || "Try register again");
    yield put({ ...resetError });
  }
}

function* restorePassword({ payload }) {
  try {
    const data = yield call(Api.post, "/api/users/password/restore", payload);
    setToken(data.token);
    yield put({ ...restoreSuccess() });
    notifier.success("Your password has been updated");
    yield put({ ...logout() });
    yield put(push("/auth/login"));
  } catch (e) {
    notifier.error(e.error || "Try restore again");
    yield put({ ...restoreError });
  }
}

function* getUser() {
  try {
    const { user } = yield call(Api.get, "/api/users/profile");
    yield put({ ...profileActions.getUser(user) });
  } catch (e) {
    yield put({ ...profileActions.getUserFailed() });
  }
}

function* updateUser({
  payload: { withPassword, password, passwordOld, telegramAccount, email },
}) {
  try {
    yield call(Api.post, `/api/users/profile`, {
      user: { telegramAccount },
    });
    yield put({
      ...profileActions.updateUserEnd({ data: { telegramAccount } }),
    });
    if (withPassword) {
      const data = yield call(Api.post, "/api/users/password", {
        passwordOld,
        passwordNew: password,
      });
      notifier.success("Profile and password have been update");
    } else {
      notifier.success("Profile has been update");
    }
  } catch (err) {
    notifier.error("Failed update user");
    yield put({ ...profileActions.updateUserFailed() });
  }
}

function* getLots({ payload }) {
  const { status, page } = payload;
  const { limit } = config.lots;
  let url = `/api/lots?limit=${limit}&skip=${page * limit}`;
  url = status ? url + `&status=${status}` : url;
  url =
    status === "active" || status === "comingSoon" || !status
      ? url + `&sortMethod=startAt`
      : url;
  url = status === "sold" ? url + `&sortMethod=endAt` : url;

  try {
    const { list, count } = yield call(Api.get, url);
    yield put({
      ...lotsActions.getLotsEnd({ list, count: count / limit, page }),
    });
  } catch (e) {
    notifier.error("We have fallen to get lots");
    yield put({ ...lotsActions.getLotsError() });
  }
}

function* deleteLot({ payload }) {
  const { id } = payload;
  try {
    yield call(Api.delete, `/api/lots/${id}`);
    yield put({ ...lotsActions.deleteLotEnd(id) });
    yield put(push("/lots"));
  } catch (e) {
    notifier.error("Delete Error");
    yield put({ ...lotsActions.deleteLotError() });
  }
}

function* getLot({ payload }) {
  const { id } = payload;
  try {
    const { lot, estimate } = yield call(Api.get, `/api/lots/${id}`);
    const { list } = yield call(
      Api.get,
      `/api/bids/?lotId=${id}&limit=${config.bids.limit}`
    );
    yield put({ ...lotActions.getLotEnd({ lot: { ...lot, estimate }, list }) });
  } catch (e) {
    notifier.error("We have fallen to get a lot");
    yield delay(1000);
    yield put(push("/lots"));
    yield put({ ...lotActions.getLotError() });
  }
}

function* bidCreate({ payload }) {
  const { value, lotId } = payload;
  try {
    const { bid } = yield call(Api.post, `/api/bids/`, { value, lotId });
    yield put({ ...lotActions.showBid() });
    yield delay(config.bids.checkMark);
    yield put({ ...lotActions.hideBid() });
  } catch (e) {
    notifier.error("bid fall");
    yield put({ ...lotActions.bidError() });
  }
}

function* createLot({ payload: { image, ...payload } }) {
  const formData = new FormData();
  if (image.name) {
    formData.append("file", image, image.name);
  }

  try {
    if (image.name) {
      const { ids } = yield Api.post("/api/upload", formData);
      payload.image = Object.values(ids)[0];
    }
    let { lot } = yield Api.post("/api/lots/", { ...payload });

    yield put({ ...lotActions.createLotEnd() });
    lot = new LotModel(lot);
    notifier.success("Success");
    yield delay(1000);
    yield put(push(`/lot/${lot.id}`));
  } catch (e) {
    notifier.error(e.error);
    yield put({ ...lotActions.createLotError() });
  }
}

function* updateLot({ payload: { id, image, ...payload } }) {
  const formData = new FormData();
  if (image.name) {
    formData.append("file", image, image.name);
  }

  try {
    if (image.name) {
      const { ids } = yield Api.post("/api/upload", formData);
      payload.image = Object.values(ids)[0];
    }

    yield Api.put(`/api/lots/${id}`, { ...payload });

    yield put({ ...lotActions.updateLotEnd() });
    notifier.success("Success");
    yield put(push(`/lot/${id}`));
  } catch (e) {
    notifier.error(e.error);
    yield put({ ...lotActions.updateLotError() });
  }
}

function* getUsers({ payload }) {
  const { page } = payload;
  const { limit } = config.userList;
  try {
    const { list, count } = yield call(
      Api.get,
      `/api/users/?limit=${limit}&skip=${page * limit}`
    );
    yield put({
      ...usersActions.getUsersEnd({
        list,
        count: count / limit,
        page,
      }),
    });
  } catch (e) {
    notifier.error("Get users Error");
    yield put({ ...usersActions.getUsersError() });
  }
}

function* searchUsers({ payload }) {
  const { search } = payload;
  const { limit } = config.userList;
  let url = "/api/users/";

  if (!search) {
    url += `?limit=${limit}&skip=${0 * limit}`;
  }
  try {
    const { list, count } = yield call(Api.get, url);
    yield put({
      ...usersActions.searchUsersEnd({
        list,
        search,
        count: count / limit,
        page: 0,
      }),
    });
  } catch (e) {
    notifier.error("Search users Error");
    yield put({ ...usersActions.searchUsersError() });
  }
}

function* deleteUsers({ payload }) {
  const { id } = payload;
  try {
    yield call(Api.delete, `/api/users/profile/${id}`);
    yield put({ ...usersActions.deleteUsersEnd(id) });
  } catch (e) {
    notifier.error("Delete Error");
    yield put({ ...usersActions.deleteUsersError() });
  }
}

function* createNote({ payload }) {
  const { id, note } = payload;
  try {
    const { user } = yield call(Api.post, `/api/users/profile/${id}/addInfo`, {
      user: { note },
    });
    yield put({ ...usersActions.createNoteEnd(user) });
    notifier.success("Success");
  } catch (e) {
    notifier.error("Note create Error");
    yield put({ ...usersActions.createNoteError() });
  }
}

function* generate({ payload }) {
  try {
    const { list } = yield call(Api.post, `/api/keyCodes`, payload);
    yield put({ ...keysActions.generateEnd(list) });
    notifier.success("Success");
  } catch (e) {
    notifier.error("generate Error");
    yield put({ ...keysActions.generateError() });
  }
}

function* resendWorker({ payload }) {
  try {
    const { list } = yield call(Api.post, `/api/users/sendEmail`, {
      ...payload,
    });
    notifier.success("Success");
  } catch (e) {
    notifier.error("Error");
  }
}

export default function* authSaga() {
  yield takeLatest(loginStart, loginWorker);
  yield takeLatest(registerStart, registerWorker);
  yield takeLatest(resetStart, resetPassword);
  yield takeLatest(restoreStart, restorePassword);
  yield takeLatest(confirmStart, confirmEmail);
  yield takeLatest(resend, resendWorker);
  yield takeLatest(lotsActions.getLotsStart, getLots);
  yield takeLatest(lotsActions.deleteLotStart, deleteLot);
  yield takeLatest(profileActions.getUserRequest, getUser);
  yield takeLatest(profileActions.updateUser, updateUser);
  yield takeLatest(lotActions.getLotStart, getLot);
  yield takeLatest(lotActions.bidStart, bidCreate);
  yield takeLatest(lotActions.createLotStart, createLot);
  yield takeLatest(lotActions.updateLotStart, updateLot);
  yield takeLatest(usersActions.getUsersStart, getUsers);
  yield takeLatest(usersActions.deleteUsersStart, deleteUsers);
  yield takeLatest(usersActions.createNoteStart, createNote);
  yield takeLatest(usersActions.searchUsersStart, searchUsers);
  yield takeLatest(keysActions.generateStart, generate);
}
