import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { logout, logoutStart, logoutCancel } from "store";
import { Switch, Route, Redirect } from "react-router-dom";
import history from "utils/history";
import HamburgerMenu from "react-hamburger-menu";

import { css, createGlobalStyle } from "styled-components";
import ConfirmModal from "components/modal/confirm";
import Logo from "components/logo";
import { HeaderWrap, HeaderItem, MenuWrap } from "./styled";
import { LinkStyled } from "components/styled";

const GlobalStyle = createGlobalStyle`
  body{ 
    overflow: ${(props) => (props.open ? "hidden" : "none")};
  }
`;

function Header({ theme }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { modal, user } = useSelector(
    ({ auth, profile }) => ({
      modal: auth.modal,
      user: profile.user,
    }),
    shallowEqual
  );
  const openModal = () => {
    dispatch(logoutStart());
  };
  const handleLogOut = () => {
    history.push("/");
    setTimeout(() => {
      dispatch(logout());
    }, 0);
  };
  const handleLogOutCancel = () => {
    dispatch(logoutCancel());
  };
  return (
    <HeaderWrap
      theme={theme}
      className="container-fluid d-flex align-items-center justify-content-between"
    >
      <Logo />
      <HamburgerMenu
        className="block-for-tablet burger"
        isOpen={open}
        menuClicked={() => setOpen(!open)}
        width={27}
        height={25}
        strokeWidth={1}
        rotate={0}
        color="black"
        borderRadius={0}
        animationDuration={0.5}
      />
      <MenuWrap open={open} className="align-items-center">
        {user.isAdmin && (
          <>
            <HeaderItem>
              <LinkStyled to="/admin/user-list">Users</LinkStyled>
            </HeaderItem>
            <HeaderItem>
              <LinkStyled to="/admin/generate">Key-codes</LinkStyled>
            </HeaderItem>
            <HeaderItem>
              <LinkStyled to="/admin/add-lot">Add Lot</LinkStyled>
            </HeaderItem>
          </>
        )}
        <HeaderItem>
          <LinkStyled to="/lots">Lots</LinkStyled>
        </HeaderItem>
        <HeaderItem>
          <LinkStyled to="/settings">Settings</LinkStyled>
        </HeaderItem>
        <HeaderItem onClick={openModal} className="cursor-pointer">
          Logout
        </HeaderItem>
      </MenuWrap>
      <ConfirmModal
        text="Are you sure that you want to LogOut?"
        visible={modal}
        onSave={handleLogOut}
        onCancel={handleLogOutCancel}
      />
      <GlobalStyle open={open} />
    </HeaderWrap>
  );
}

export default Header;
