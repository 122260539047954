import React, { memo, useEffect } from "react";
import * as InnerModel from "react-modal";

const Modal = memo((props) => {
  const { visible, children, style } = props;
  return (
    <InnerModel
      isOpen={visible}
      ariaHideApp={false}
      style={style}
      className="ReactModal__Content--custom"
    >
      {children}
    </InnerModel>
  );
});

Modal.defaultProps = {
  style: {},
};

export default Modal;
