import React, { useState, useEffect } from "react";

import Camera from "static/images/camera.svg";
import styled from "styled-components";
import { rem } from "utils/common";
import { colors, device } from "components/variable";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  min-height: ${rem(220)};
  background: url(${Camera}) ${colors.grey} center center no-repeat;
  @media ${device.laptopS} {
    min-height: ${rem(320)};
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
`;

const Image = styled.img`
  max-width: 100%;
`;

function Upload({ onChange, initialImage }) {
  const [image, setImage] = useState(null);
  const handleChange = (e) => {
    const item = e.target.files[0];
    onChange(item);
    setImage(URL.createObjectURL(item));
  };
  return (
    <Wrap className="d-flex align-items-center justify-content-center">
      <Label htmlFor="upload-photo"></Label>
      <input
        id="upload-photo"
        className="d-none"
        type="file"
        onChange={handleChange}
      />
      <Image src={image || initialImage} />
    </Wrap>
  );
}

export default Upload;
