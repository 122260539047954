import { rem } from "utils/common";
import { device, colors } from "components/variable";
import styled from "styled-components";

export const Col = styled.div`
  padding: 0 !important;
  @media ${device.laptopS} {
    padding-bottom: ${rem(70)}!important;
    &:last-child {
      border: none;
    }
  }
  @media ${device.laptopXS} {
    border-right: ${rem(2)} solid ${colors.yellow};
  }
`;

export const Left = styled.div`
  padding: 0 ${rem(15)};
  @media ${device.laptopS} {
    padding: 0 ${rem(30)};
  }
`;

export const Center = styled.div`
  max-width: ${rem(510)};
  margin: 0 auto;
  margin-top: ${rem(30)};
  padding: 0 ${rem(15)};
  display: flex;
  flex-direction: column-reverse;
  @media ${device.laptopS} {
    display: block;
    margin: 0 auto;
    padding-top: ${rem(44)};
    max-width: ${rem(510)};
  }
`;

export const CenterContent = styled.div`
  margin-top: ${rem(10)};
  @media ${device.laptopS} {
    margin-top: ${rem(80)};
  }
`;

export const BidsWrap = styled.div`
  padding-right: ${rem(2)};
  max-height: ${rem(310)};
  overflow-y: scroll;
`;

export const Right = styled.div`
  position: relative;
  max-width: ${rem(510)};
  margin: 0 auto;
  padding: 0 ${rem(15)};
  padding-top: ${rem(30)};
  @media ${device.laptopS} {
    height: 100%;
    max-width: ${rem(370)};
    padding-top: ${rem(44)};
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => (props.theme === "small" ? rem(10) : rem(20))};
`;

export const Status = styled.h4`
  color: ${colors.red};
  font-size: ${rem(18)};
  @media ${device.laptopM} {
    font-size: ${rem(22)};
  }
`;

export const LastSecond = styled.h4`
  color: ${colors.black};
  font-size: ${rem(70)};
  @media ${device.tablet} {
    font-size: ${rem(100)};
  }
  @media ${device.laptopM} {
    font-size: ${rem(114)};
  }
`;

export const Panel = styled.div`
  @media ${device.laptopM} {
    position: absolute;
    right: ${rem(16)};
    bottom: 0;
    margin-bottom: ${rem(-40)};
  }
`;

export const UserItem = styled.div`
  font-size: ${rem(11)};
  color: ${colors.black};
`;
