import React, { memo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { resetStart } from "store";

import Layout from "components/layout/auth";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

function ResetPassword() {
  const dispatch = useDispatch();
  const onReset = (values) => {
    dispatch(resetStart(values));
  };
  return (
    <Layout
      title="New password"
      description="Please, enter your registration email"
      linkText="Log in"
      href="/auth/login"
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().email("wrong email").required("required"),
        })}
        onSubmit={onReset}
      >
        {({ handleSubmit, isValid, error }) => {
          return (
            <FormWrap onSubmit={handleSubmit}>
              <InputWithLabel label="Email" name="email" type="email" />
              <Button
                color={isValid && !error ? "red" : "grey"}
                disabled={!isValid || error}
                className="mt-40"
              >
                Submit
              </Button>
            </FormWrap>
          );
        }}
      </Formik>
    </Layout>
  );
}

export default ResetPassword;
