import { createSlice, configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import history from "utils/history";
import { isAuth, remoteToken } from "utils/auth";
import rootSaga from "./saga";

import { UserModel, LotModel, LotFullModel } from "models";
import { BidModel } from "models/bid";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: isAuth(),
    isLoading: false,
    modal: false,
  },
  reducers: {
    registerStart: (state) => {
      state.isLoading = true;
    },
    registerSuccess: (state) => {
      state.isLoading = false;
      // state.isAuth = true
    },
    registerError: (state) => {
      state.isLoading = false;
    },
    logoutStart: (state) => {
      state.modal = true;
    },
    logoutCancel: (state) => {
      state.modal = false;
    },
    logout: (state) => {
      state.isAuth = false;
      state.modal = false;
      remoteToken();
    },
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state) => {
      state.isLoading = false;
      state.isAuth = true;
    },
    loginError: (state) => {
      state.isLoading = false;
    },
    resetStart: (state) => {
      state.isLoading = true;
    },
    resetSuccess: (state) => {
      state.isLoading = false;
    },
    resetError: (state, { e }) => {
      state.isLoading = false;
    },
    restoreStart: (state) => {
      state.isLoading = true;
    },
    restoreSuccess: (state, { modalVisible }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.modalVisible = modalVisible || true;
    },
    restoreError: (state, { e }) => {
      state.modalVisible = true;
      state.isLoading = false;
    },
    confirmStart: (state) => {},
    confirmSuccess: (state, { modalVisible }) => {},
    confirmError: (state, { e }) => {},
    resend: (state) => {},
  },
});

export const {
  logoutStart,
  logoutCancel,
  logout,
  loginStart,
  loginSuccess,
  loginError,
  registerStart,
  registerSuccess,
  registerError,
  resetStart,
  resetSuccess,
  resetError,
  restoreStart,
  restoreSuccess,
  restoreError,
  confirmStart,
  confirmSuccess,
  confirmError,
  resend,
} = authSlice.actions;

export const lotSlice = createSlice({
  name: "lot",
  initialState: {
    lot: {},
    bid: {
      add: false,
      loading: false,
    },
    bidAdded: false,
    loading: false,
    modalWinner: false,
    modalDelete: false,
  },
  reducers: {
    bidStart: (state) => {
      state.bid.loading = true;
    },
    bidError: (state) => {
      state.bid.loading = false;
    },
    bidEnd: (state, { payload }) => {
      const { bid, currentPrice } = payload;
      state.bid.loading = false;
      let currentBid = new BidModel(bid);
      state.lot.bids = [currentBid, ...state.lot.bids];
      state.lot = {
        ...state.lot,
        currentPrice: currentPrice,
        currentBid,
      };
      if (bid.type !== "ws") {
        state.bidAdded = true;
      }
    },
    lotWinner: (state, { payload }) => {
      state.lot.winner = new UserModel(payload);
      state.modalWinner = true;
    },
    modalClose: (state) => {
      state.modalWinner = false;
    },
    getLotStart: (state) => {
      state.loading = true;
    },
    getLotError: (state) => {
      state.loading = false;
    },
    getLotEnd: (state, { payload }) => {
      const { lot, list } = payload;
      state.loading = false;
      state.lot = new LotFullModel(lot);
      state.lot.bids = list.map((item) => new BidModel(item));
    },
    showBid: (state) => {
      state.bidAdded = true;
    },
    hideBid: (state) => {
      state.bidAdded = false;
    },
    createLotStart: (state) => {
      state.loading = true;
    },
    createLotError: (state) => {
      state.loading = false;
    },
    createLotEnd: (state, { payload }) => {
      state.loading = false;
    },
    updateLotStart: (state) => {
      state.loading = true;
    },
    updateLotError: (state) => {
      state.loading = false;
    },
    updateLotEnd: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const lotActions = lotSlice.actions;

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: new UserModel(),
    loading: false,
  },
  reducers: {
    getUserRequest: (state) => {
      state.loading = true;
    },
    getUserFailed: (state) => {
      state.loading = false;
    },
    getUser: (state, { payload }) => {
      state.loading = false;
      state.user = new UserModel(payload);
    },
    updateUser: (state) => {
      state.loading = true;
    },
    updateUserFailed: (state) => {
      state.loading = false;
    },
    updateUserEnd: (state, { payload }) => {
      const { data } = payload;
      state.loading = false;
      let obj = new UserModel({ ...state.user, ...data });
      state.user = obj;
    },
  },
});

export const profileActions = profileSlice.actions;

const lotsSlice = createSlice({
  name: "lots",
  initialState: {
    modalDelete: false,
    lots: {},
    loading: false,
    pageCount: 0,
    currentPage: 0,
  },
  reducers: {
    getLotsStart: (state) => {
      state.loading = true;
    },
    getLotsError: (state) => {
      state.loading = false;
    },
    getLotsEnd: (state, { payload }) => {
      const { list, count, page } = payload;
      state.loading = false;
      const obj = {};
      list
        .map((item) => new LotModel(item))
        .map((item) => (obj[item.id] = item));
      state.lots = obj;
      state.pageCount = Number.isInteger(count) ? count : Math.floor(count) + 1;
      state.currentPage = page;
    },
    deleteLotModal: (state) => {
      state.modalDelete = !state.modalDelete;
    },
    deleteLotStart: (state) => {},
    deleteLotError: (state) => {},
    deleteLotEnd: (state, { payload: id }) => {
      state.modalDelete = false;
      delete state.lots[id];
    },
  },
});

export const lotsActions = lotsSlice.actions;

const keysSlice = createSlice({
  name: "keys",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    generateStart: (state) => {
      state.loading = true;
    },
    generateEnd: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
    },
    generateError: (state) => {
      state.loading = false;
    },
  },
});

export const keysActions = keysSlice.actions;

const usersSlice = createSlice({
  name: "users",
  initialState: {
    list: {},
    modal: false,
    loading: false,
    pageCount: 0,
    currentPage: 0,
    search: "",
  },
  reducers: {
    getUsersStart: (state) => {
      state.loading = true;
    },
    getUsersError: (state) => {
      state.loading = false;
    },
    getUsersEnd: (state, { payload }) => {
      state.loading = false;
      const obj = {};
      const { list, count, page } = payload;
      list
        .map((item) => new UserModel(item))
        .map((item) => (obj[item.id] = item));
      state.list = obj;
      state.pageCount = Number.isInteger(count) ? count : Math.floor(count) + 1;
      state.currentPage = page;
    },
    deleteUsersStart: (state) => {
      state.loading = true;
    },
    deleteUsersError: (state) => {
      state.loading = false;
    },
    deleteUsersEnd: (state, { payload }) => {
      state.loading = false;
      state.modal = false;
      delete state.list[payload];
    },
    toggleModal: (state) => {
      state.modal = !state.modal;
    },
    createNoteStart: (state) => {},
    createNoteError: (state) => {},
    createNoteEnd: (state, { payload }) => {
      const { user } = payload;
    },
    searchUsersStart: (state, { payload }) => {
      state.loading = true;
    },
    searchUsersError: (state, { payload }) => {
      state.loading = false;
    },
    searchUsersEnd: (state, { payload }) => {
      state.loading = false;
      const { search, list, count, page } = payload;
      const obj = {};
      if (search) {
        list
          .map((item) => new UserModel(item))
          .map((item) => {
            if (item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
              obj[item.id] = item;
            }
          });
      } else {
        list
          .map((item) => new UserModel(item))
          .map((item) => (obj[item.id] = item));
        state.pageCount = Number.isInteger(count)
          ? count
          : Math.floor(count) + 1;
        state.currentPage = page;
      }

      state.search = search;
      state.list = obj;
    },
  },
});

export const usersActions = usersSlice.actions;

const sagaMiddleware = createSagaMiddleware();

function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth: authSlice.reducer,
    lot: lotSlice.reducer,
    lots: lotsSlice.reducer,
    keys: keysSlice.reducer,
    profile: profileSlice.reducer,
    users: usersSlice.reducer,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    return appReducer(state, action);
  };

  return rootReducer;
}

export const store = configureStore({
  reducer: createReducer(),
  middleware: [sagaMiddleware, routerMiddleware(history)],
});

sagaMiddleware.run(rootSaga);
