import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Timer from "components/timer";
import LotSettingsPanel from "components/lot-settings-panel";
import ImagePreview from "components/image-preview";
import TextOverflow from "components/text-overflow";
import { SmallText, Text, SmallTitle } from "components/styled";
import { rem } from "utils/common";
import { colors, device } from "components/variable";
import styled from "styled-components";

const ItemWrap = styled.div`
  margin-bottom: ${rem(40)};
  background: ${colors.white};
  position: relative;
  &:hover {
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  }
`;

const ItemRow = styled.div`
  @media ${device.laptopM} {
    min-height: ${rem(320)};
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: ${rem(20)};
`;

const CenterBlock = styled.div`
  width: 100%;
  padding-top: ${rem(0)};
  padding-bottom: ${rem(44)};
  @media ${device.tablet} {
    padding: ${rem(44)} ${rem(10)};
    max-width: ${rem(490)};
    margin: 0 auto;
  }
`;

const RightBlock = styled.div`
  @media ${device.tablet} {
    border-left: ${rem(2)} solid ${colors.yellow};
    padding: ${rem(44)} ${rem(15)};
  }
`;

const RightBlockContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  @media ${device.tablet} {
    max-width: ${rem(340)};
    margin: 0 auto;
    flex-direction: row;
  }
`;
const Panel = styled.div`
  @media ${device.laptopS} {
    position: absolute;
    right: ${rem(10)};
    bottom: ${rem(0)};
  }
  @media ${device.laptopXS} {
    right: ${rem(15)};
  }
  @media ${device.laptopM} {
    right: ${rem(35)};
    bottom: ${rem(14)};
  }
`;

const OverflowWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${colors.white};
  opacity: 0.6;
`;

function Lot({ data, onDelete }) {
  const {
    id,
    description,
    status,
    statusLabel,
    startAt,
    endAt,
    startPrice,
    currentPrice,
    estimate,
    dayEstimate,
    image,
  } = data;
  const history = useHistory();

  const onEdit = () => {
    history.push(`/admin/lot/${id}`);
  };

  const onCopy = () => {
    history.push(`/admin/add-lot/${id}`);
  };

  const { user } = useSelector((state) => {
    return {
      user: state.profile.user,
    };
  }, shallowEqual);

  const lotBlock = (
    <>
      <ItemRow className="row flex-column-reverse flex-md-row">
        <div className="col-12 col-md-12 col-xl-4 p-0 block-for-desktop">
          <ImagePreview color="red" image={image} />
        </div>
        <div className="col-12 col-md-6 col-xl-5">
          <CenterBlock>
            <Row>
              <Text className="medium">Start date : </Text>
              <Text color="black">{startAt}</Text>
            </Row>
            <Row>
              <Text className="medium">End date : </Text>
              <Text color="black">{endAt}</Text>
            </Row>
            <Text className="medium mt-5">Description:</Text>
            <SmallText className="mt-2" color="black">
              <TextOverflow lines={4}>{description}</TextOverflow>
            </SmallText>
          </CenterBlock>
        </div>

        <RightBlock isAdmin={user.isAdmin} className="col-12 col-md-6 col-xl-3">
          <RightBlockContent>
            <div className="w100">
              <Row>
                <Text className="medium">Status:</Text>
                <SmallTitle color="black" className="text-capitalize">
                  {statusLabel}
                </SmallTitle>
              </Row>
              <Row className="mb-8">
                <Text className="medium">End in: </Text>
                <Timer estimate={estimate} dayEstimate={dayEstimate} />
              </Row>
            </div>
            <div className="w100">
              <Row className="mt-5">
                <Text className="medium">Start Price: </Text>
                <Text color="black">
                  <b>${startPrice}</b>
                </Text>
              </Row>

              <Row>
                <Text className="medium">Current bid : </Text>
                <Text color="red">
                  <b>{currentPrice ? `$${currentPrice}` : "no active bids"}</b>
                </Text>
              </Row>
            </div>
          </RightBlockContent>
        </RightBlock>
        <div className="col-12 col-md-12 col-xl-4 block-for-mobile p-0">
          <ImagePreview color="red" image={image} />
        </div>
      </ItemRow>
      {status === "sold" && <OverflowWrap />}
    </>
  );

  return (
    <ItemWrap className="col-12 position-relative">
      {status === "active" || user.isAdmin ? (
        <Link className="w100" to={`/lot/${id}`}>
          {lotBlock}
        </Link>
      ) : (
        lotBlock
      )}
      {user.isAdmin && (
        <Panel>
          <LotSettingsPanel
            mode="desktop"
            status={status}
            onDelete={onDelete}
            onEdit={onEdit}
            onCopy={onCopy}
          />
        </Panel>
      )}
    </ItemWrap>
  );
}

export default Lot;
