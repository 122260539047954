import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Formik } from "formik";
import Select from "react-select";
import socketSrv from "services/socket";
import config from "_config";

import { lotActions } from "store";

import CheckMark from "static/images/check-mark.gif";
import { Icon } from "components/icon";
import LotSettingsPanel from "components/lot-settings-panel";
import {
  Col,
  Row,
  Right,
  Status,
  LastSecond,
  Panel,
} from "containers/Lot/styled";
import Timer from "components/timer";
import Button from "components/button";
import { Text, SmallTitle } from "components/styled";
import { colors } from "components/variable";
import { useHistory } from "react-router-dom";

const selectCustomStyles = {
  container: (provided, state) => ({
    ...provided,
    minWidth: "120px",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "black!important" : colors.grey,
    boxShadow: "none",
    color: colors.black,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none!important",
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: colors.greyBg,
    fontWeight: "500",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: colors.black,
    background: state.isSelected ? colors.white : colors.greyBg,
    fontWeight: "500",
  }),
  indicators: (provided) => ({
    ...provided,
    border: "none",
  }),
};

function Bid({ user, lot, onDelete, bidAdded }) {
  let timerId = 0;
  const [submitDisable, setSubmitDisable] = useState(false);
  const [estimateValue, setEstimateValue] = useState(lot.estimate);
  const [timerValue, setTimerValue] = useState(null);
  const [bottomTimerVisible, setBottomTimerVisible] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAdmin } = user;
  const {
    id,
    status,
    statusLabel,
    startPrice,
    estimate,
    dayEstimate,
    bidSteps,
  } = lot;

  const timerWorker = (value) => {
    clearInterval(timerId);
    setTimerValue(Math.ceil(value / 1000));
    const newTimerId = setInterval(() => {
      setTimerValue((timerValue) => {
        if (timerValue <= 1) {
          clearInterval(newTimerId);
        } else {
          return timerValue - 1;
        }
      });
    }, 1000);
    timerId = newTimerId;
  };

  const bidWorker = (e) => {
    const { bid, estimate } = e;
    setEstimateValue(estimate);
    if (bid.lotId === lot.id) {
      if (estimate <= config.timer.delay) {
        setBottomTimerVisible(true);
        timerWorker(estimate);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setBottomTimerVisible(true);
      timerWorker(config.timer.delay);
    }, estimate - config.timer.delay);
  }, [estimate]);

  useEffect(() => {
    socketSrv.on("bid", bidWorker);
  }, []);

  const bidSubmit = ({ bid: { value } }) => {
    setSubmitDisable(true);
    setTimeout(() => {
      setSubmitDisable(false);
    }, config.bids.delay);
    dispatch(lotActions.bidStart({ lotId: id, value }));
  };

  const onEdit = () => {
    history.push(`/admin/lot/${id}`);
  };

  const onCopy = () => {
    history.push(`/admin/add-lot/${id}`);
  };

  const form = isAdmin ? (
    <Select className="mt-3" styles={selectCustomStyles} options={bidSteps} />
  ) : (
    <Formik
      initialValues={{
        bid: bidSteps[0],
      }}
      onSubmit={bidSubmit}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Row className="justify-content-between flex-wrap">
              <Select
                styles={selectCustomStyles}
                options={bidSteps}
                onChange={(option) => setFieldValue("bid", option)}
                placeholder={bidSteps[0].label}
              />
              {bidAdded && (
                <img
                  src={CheckMark}
                  style={{ width: "40px", height: "40px" }}
                />
              )}
              <Button
                disabled={submitDisable}
                type="submit"
                className="ml-1"
                mode="small"
              >
                Submit
              </Button>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
  return (
    <>
      <Col className="col-12 col-md-12 col-lg-6 col-xl-3">
        <Right>
          <Row>
            <Text color="darkGrey" className="medium">
              Status:
            </Text>
            <SmallTitle className="text-capitalize" color="black">
              {statusLabel}
            </SmallTitle>
          </Row>
          <Row>
            <Text color="darkGrey" className="medium">
              End in:
            </Text>
            {!bottomTimerVisible && (
              <Timer estimate={estimateValue} dayEstimate={dayEstimate} />
            )}
          </Row>
          <Row className="mt-4">
            <Text color="darkGrey" className="medium">
              Start Price:
            </Text>
            <Text color="black">
              <b>${startPrice}</b>
            </Text>
          </Row>
          <SmallTitle color="black" className="mt-4">
            Bidding
          </SmallTitle>
          {form}
          {!isAdmin && bottomTimerVisible && status === "active" && (
            <Status color="red" className="mt-5 text-center">
              <b>Your last chance to make a bid!</b>
            </Status>
          )}
          {bottomTimerVisible && status === "active" && (
            <LastSecond className="mt-5 mb-4 text-center">
              {timerValue}
            </LastSecond>
          )}
          {isAdmin && (
            <Panel>
              <LotSettingsPanel
                className="align-self-end"
                status={status}
                onDelete={onDelete}
                onEdit={onEdit}
                onCopy={onCopy}
              />
            </Panel>
          )}
        </Right>
      </Col>
    </>
  );
}

export default Bid;
