import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Yup from "yup";
import "./static/style/index.scss";
import notifier from "simple-react-notifications";
import "simple-react-notifications/dist/index.css";
import "react-dropdown/style.css";

notifier.configure({
  autoClose: 2000,
});

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
