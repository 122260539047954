import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { usersActions } from "store";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import ConfirmModal from "components/modal/confirm";
import Preloader from "components/preloader";
import ReactPaginate from "react-paginate";
import { PaginationWrap } from "components/styled";
import LayoutBasic from "components/layout/basic";
import {
  TableWrap,
  Table,
  TableRow,
  TableRowHead,
  TableCell,
} from "components/styled";
import { Icon } from "components/icon";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { UsersInput } from "components/styled";

const head = ["User name", "Email", "Telegram", "Key-code", "Note"];

const validationObj = Yup.object({
  note: Yup.string(),
});

function UserList() {
  const [current, setCurrent] = useState("");
  const dispatch = useDispatch();
  const { list, search, pageCount, currentPage, modal, loading } = useSelector(
    (state) => {
      return {
        list: state.users.list,
        search: state.users.search,
        pageCount: state.users.pageCount,
        currentPage: state.users.currentPage,
        modal: state.users.modal,
        loading: state.users.loading,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(usersActions.getUsersStart({ page: 0 }));
  }, []);

  const getUsers = ({ selected: page }) => {
    dispatch(usersActions.getUsersStart({ page }));
  };

  const noteCreate = (id, values) => {
    if (values.note) {
      dispatch(usersActions.createNoteStart({ id, note: values.note }));
    }
  };

  const deleteUser = (id) => () => {
    dispatch(usersActions.deleteUsersStart({ id }));
  };

  const openModal = (id) => {
    setCurrent(id);
    dispatch(usersActions.toggleModal());
  };

  const handleSearch = ({ search }) => {
    dispatch(usersActions.searchUsersStart({ search }));
  };
  const content = loading ? (
    <Preloader />
  ) : (
    <>
      <div className="w100 position-relative">
        <Formik
          initialValues={{
            search: search,
          }}
          onSubmit={handleSearch}
        >
          {({ handleSubmit, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <UsersInput
                  type="text"
                  name="search"
                  value={values.search}
                  onChange={handleChange}
                />
              </form>
            );
          }}
        </Formik>
      </div>
      <TableWrap className="custom-scroll">
        <Table>
          <TableRowHead>
            {head.map((i, idx) => (
              <TableCell key={idx}>{i}</TableCell>
            ))}
          </TableRowHead>
          {Object.values(list).length > 0 &&
            Object.values(list).map((i, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.email}</TableCell>
                  <TableCell>{i.telegram}</TableCell>
                  <TableCell>{i.keyCode}</TableCell>
                  <TableCell className=" d-flex align-items-center justify-content-between">
                    <Formik
                      initialValues={{
                        note: i.note,
                      }}
                      validationSchema={validationObj}
                      onSubmit={(values) => noteCreate(i.id, values)}
                    >
                      {({ handleSubmit, isValid, error }) => {
                        return (
                          <form theme="center" onSubmit={handleSubmit}>
                            <InputWithLabel
                              theme="noLabel"
                              mode="noBorder"
                              name="note"
                              type="text"
                            />
                          </form>
                        );
                      }}
                    </Formik>
                    <Icon
                      component="Trash"
                      onClick={() => openModal(i.id)}
                      className="cursor-pointer pathHover"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
      </TableWrap>
      {!search && (
        <PaginationWrap className="mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakClassName={"break-me"}
            pageCount={pageCount}
            forcePage={currentPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={getUsers}
            containerClassName={"pagination mb-4"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </PaginationWrap>
      )}

      <ConfirmModal
        text="Are you sure that you want to delete user?"
        visible={modal}
        onSave={deleteUser(current)}
        onCancel={() => dispatch(usersActions.toggleModal())}
      />
    </>
  );
  return <LayoutBasic>{content}</LayoutBasic>;
}

export default UserList;
