import React, { useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Tooltip from "react-tooltip-lite";
import copy from "copy-to-clipboard";
import { usersActions } from "store";

import CustomTooltip from "components/tooltip";
import ConfirmModal from "components/modal/confirm";
import {
  Col,
  Row,
  CenterContent,
  Left,
  Center,
  BidsWrap,
  UserItem,
} from "containers/Lot/styled";
import TextOverflow from "components/text-overflow";
import { Text, SmallText, SmallTitle } from "components/styled";
import Image from "static/images/lot.jpg";
import { Icon } from "components/icon";

function Info({ lot, user }) {
  const [current, setCurrent] = useState("");
  const dispatch = useDispatch();
  const {
    id,
    description,
    status,
    startAt,
    endAt,
    bids,
    currentPrice,
    image,
  } = lot;
  const { isAdmin } = user;
  let contentRef = useRef();
  let childrenRef = useRef();

  const { modal } = useSelector((state) => {
    return {
      modal: state.users.modal,
    };
  }, shallowEqual);

  const deleteUser = (id) => () => {
    dispatch(usersActions.deleteUsersStart({ id }));
  };

  const openModal = (id) => {
    setCurrent(id);
    dispatch(usersActions.toggleModal());
  };

  return (
    <>
      <Col className="col-12 col-md-12 col-lg-12 col-xl-4">
        <img src={image ? image : Image} className="w100" />
        <Left>
          <Text className="mt-5 mb-3 medium">Description:</Text>
          <SmallText color="black">
            <TextOverflow lines={12} mode="withShow">
              {description}
            </TextOverflow>
          </SmallText>
        </Left>
      </Col>
      <Col className="col-12 col-md-12 col-lg-6 col-xl-5">
        <Center>
          <Row>
            <Text color="darkGrey" className="medium">
              Start date :{" "}
            </Text>
            <Text color="black">{startAt}</Text>
          </Row>
          <Row>
            <Text color="darkGrey" className="medium">
              End date :{" "}
            </Text>
            <Text color="black">{endAt}</Text>
          </Row>
          <CenterContent>
            <Row>
              <SmallTitle color="black">Current bid: </SmallTitle>
              <SmallTitle color="red">
                {currentPrice ? `$${currentPrice}` : "no active bids"}
              </SmallTitle>
            </Row>
            <SmallText color="black" className="mt-1">
              Winning place: <b>{bids[0] ? bids[0].user.name : "no bids"}</b>
            </SmallText>
            <BidsWrap className="mt-4 custom-scroll">
              {bids.map((item, idx) => {
                const { user } = item || {};
                return (
                  <Row key={idx} theme="small" className="pr-1">
                    {isAdmin ? (
                      <>
                        <CustomTooltip
                          className="target"
                          tipContentClassName=""
                          eventToggle="onClick"
                          direction="down-start"
                          childrenRef={childrenRef}
                          contentRef={contentRef}
                          content={
                            <div
                              className="pb-4 pl-4 pr-40 pt-32"
                              ref={contentRef}
                            >
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => copy(user.name)}
                              >
                                <Icon
                                  component="CopyIcon"
                                  className="mr-3 cursor-pointer"
                                />
                                <UserItem>
                                  <b>User Name</b> <br />
                                  {user.name}
                                </UserItem>
                              </div>
                              <div
                                className="d-flex align-items-center mt-3 cursor-pointer"
                                onClick={() => copy(user.telegram)}
                              >
                                <Icon
                                  component="CopyIcon"
                                  className="mr-3 cursor-pointer"
                                />
                                <UserItem>
                                  <b>Telegram</b> <br />
                                  {user.telegram}
                                </UserItem>
                              </div>
                              <div
                                className="d-flex align-items-center mt-3 cursor-pointer"
                                onClick={() => copy(user.email)}
                              >
                                <Icon
                                  component="CopyIcon"
                                  className="mr-3 cursor-pointer"
                                />
                                <UserItem className="">
                                  <b>Email</b> <br />
                                  {user.email}
                                </UserItem>
                              </div>
                              {!user.del && (
                                <div className="d-flex justify-content-end mt-3">
                                  <Icon
                                    component="Trash"
                                    onClick={() => openModal(user.id)}
                                    className="mr--3 cursor-pointer pathHover"
                                  />
                                </div>
                              )}
                            </div>
                          }
                        >
                          <div>
                            <SmallText
                              ref={childrenRef}
                              className="cursor-pointer"
                              color="black"
                            >
                              <b>{item.user.name}</b> bid for
                            </SmallText>
                          </div>
                        </CustomTooltip>
                      </>
                    ) : (
                      <SmallText color="black">
                        <b>{item.user.name}</b> bid for{" "}
                      </SmallText>
                    )}

                    <div className="d-flex align-items-center">
                      <SmallText className="mr-4" color="black">
                        <b>+ ${item.value}</b>
                      </SmallText>
                      <SmallText color="darkGrey">{item.createdAt}</SmallText>
                    </div>
                  </Row>
                );
              })}
            </BidsWrap>
          </CenterContent>
        </Center>
        <ConfirmModal
          text="Are you sure that you want to delete user?"
          visible={modal}
          onSave={deleteUser(current)}
          onCancel={() => dispatch(usersActions.toggleModal())}
        />
      </Col>
    </>
  );
}

export default Info;
