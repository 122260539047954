import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

import styled, { css } from "styled-components";
import { colors } from "components/variable";
import { rem } from "utils/common";
import Eye from "./eye";

const Wrap = styled.div`
  position: relative;
  margin-top: ${(props) => (props.theme === "noLabel" ? "0" : rem(35))};
  text-align: left;
`;

const InputWrap = styled.input`
  font-size: ${rem(14)};
  line-height: ${rem(25)};
  margin-bottom: ${(props) => (props.theme === "noLabel" ? "0" : rem(3))};
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid
    ${(props) => (props.error ? colors.red : colors.grey)};
  border-bottom: ${(props) => (props.mode === "noBorder" ? "none" : "fea")};
  background-color: transparent;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    animation-name: onAutoFillStart;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
  padding-bottom: 8px;
  padding-right: ${(props) => (props.type === "password" ? "20px" : 0)};

  @keyframes onAutoFillStart {
  }

  @keyframes onAutoFillCancel {
  }
`;

const LabelWrap = styled.label`
  color: ${(props) => (props.error ? colors.red : colors.grey)};
  font-size: ${(props) => (props.value !== "" ? rem(12) : rem(14))};
  position: absolute;
  pointer-events: none;
  top: ${(props) => (props.value !== "" || props.autoFill ? "-17px" : "5px")};
  transition: 0.2s ease all;
`;

export const InputWithLabel = ({
  type,
  label,
  theme,
  mode,
  handleChange,
  firstField,
  ...props
}) => {
  const ref = useRef();
  const [autoFill, setAutoFill] = useState(false);
  const [customType, setType] = useState(type);
  const [field, meta] = useField({ ...props, type });
  const onChangeType = (type) => {
    if (type === "password") {
      setType("text");
      setTimeout(() => {
        setType("password");
      }, 3000);
    }
  };

  const handleAutoFill = (e) => {
    setAutoFill(e.animationName === "onAutoFillStart");
  };
  return (
    <Wrap theme={theme} mode={mode}>
      <InputWrap
        onAnimationStart={handleAutoFill}
        ref={ref}
        mode={mode}
        {...field}
        {...props}
        error={meta.error}
        type={customType}
      />
      {theme !== "inputDisable" && theme !== "noLabel" && (
        <LabelWrap
          autoFill={autoFill}
          value={field.value}
          error={meta.error}
          mode={mode}
        >
          {label}
        </LabelWrap>
      )}
      {type === "password" && (
        <div onClick={() => onChangeType(customType)}>
          <Eye />
        </div>
      )}
      {meta.touched && meta.error ? (
        <div className="error mt-1">
          {label && meta.error === `${field.name} is a required field`
            ? `${label} is a required field`
            : meta.error}
        </div>
      ) : null}
    </Wrap>
  );
};

InputWithLabel.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};
