import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { confirmStart } from "store";
import queryString from "query-string";
import config from "_config";

import Layout from "components/layout/auth";
import Button, { ButtonLink } from "components/button";

function Verified() {
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      ...queryString.parse(config.location.search),
    };
    dispatch(confirmStart(payload));
  }, []);
  return (
    <Layout
      title="Congrats!"
      description="Your email has been successfully verified Enjoy!"
    >
      <ButtonLink href="/auth/login" className="mt-32">
        Log in
      </ButtonLink>
    </Layout>
  );
}

export default Verified;
