import React from "react";
import Countdown from "react-countdown";

import { Text } from "components/styled";
function Timer({ estimate, dayEstimate }) {
  return (
    <div>
      {estimate &&
        (dayEstimate > 1 ? (
          <Text color="black">{Math.floor(dayEstimate)} days</Text>
        ) : (
          <Countdown date={Date.now() + estimate} daysInHours />
        ))}
    </div>
  );
}

export default Timer;
