export class UserModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj._id || obj.id;
    this.email = obj.email || "";
    this.name = obj.name || "";
    this.telegram = obj.telegramAccount || "";
    this.isAdmin = obj.isAdmin;
    this.note = obj.note;
    this.del = obj.del;
    this.keyCode = obj.keyCode;
  }
}
