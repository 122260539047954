export const colors = {
  black: "#000000",
  lightBlack: "#373A3C",
  white: "#ffffff",
  red: "#FF4016",
  grey: "#C7C7C7",
  darkGrey: "#747474",
  greyBg: "#f0f0f0",
  lightGrey: "#ECEEEF",
  green: "#1F5148",
  lightGreen: "#B5D0BE",
  lightYellow: "#EDECD8",
  yellow: "#edecd882",
};

export const fonts = {
  roboto: "Roboto",
  comforta: "Comfortaa",
};

export const size = {
  mobileS: "0",
  mobileM: "375",
  mobileL: "425",
  mobileXL: "480",
  tabletM: "640",
  tablet: "768",
  laptopS: "992",
  laptopXS: "1200",
  laptopM: "1366",
  laptopL: "1440",
  desktop: "1920",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  mobileXL: `(min-width: ${size.mobileXL}px)`,
  tabletM: `(min-width: ${size.tabletM}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptopS: `(min-width: ${size.laptopS}px)`,
  laptopXS: `(min-width: ${size.laptopXS}px)`,
  laptopM: `(min-width: ${size.laptopM}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};
export const maxW = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  mobileXL: `(max-width: ${size.mobileXL}px)`,
  tabletM: `(max-width: ${size.tabletM}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptopS: `(max-width: ${size.laptopS}px)`,
  laptopXS: `(max-width: ${size.laptopXS}px)`,
  laptopM: `(max-width: ${size.laptopM}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
};
