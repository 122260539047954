import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { restoreStart } from "store";
import queryString from "query-string";
import config from "_config";

import Layout from "components/layout/auth";
import { InputWithLabel } from "components/form/input-with-label";
import Button from "components/button";
import { FormWrap, SmallText } from "components/styled";

function RestorePassword() {
  const dispatch = useDispatch();
  const onRestore = (values) => {
    const payload = {
      password: values.password,
      ...queryString.parse(config.location.search),
    };
    dispatch(restoreStart(payload));
  };
  return (
    <Layout
      title="Please, enter new password"
      linkText="Log in"
      href="/auth/login"
    >
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required()
            .min(2, "Seems a bit short...")
            .max(100, "Very long password."),
          confirmPassword: Yup.string()
            .required()
            .test("passwords-match", "Passwords are different", function (
              value
            ) {
              return this.parent.password === value;
            }),
        })}
        onSubmit={onRestore}
      >
        {({ handleSubmit, isValid, error }) => {
          return (
            <FormWrap onSubmit={handleSubmit}>
              <InputWithLabel
                label="Your password"
                name="password"
                type="password"
                secureTextEntry
              />
              <InputWithLabel
                label="Repeat password"
                name="confirmPassword"
                type="password"
                secureTextEntry
              />
              <Button
                color={isValid && !error ? "red" : "grey"}
                disabled={!isValid || error}
                className="mt-40"
              >
                Submit
              </Button>
            </FormWrap>
          );
        }}
      </Formik>
    </Layout>
  );
}

export default RestorePassword;
