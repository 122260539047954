import React from "react";
import Header from "containers/Header";
import { MainWrap, SmallTitle, Text, Row } from "components/styled";

function LayoutBasic({ children }) {
  return (
    <MainWrap className="container-xl">
      <Header />
      {children}
    </MainWrap>
  );
}

export default LayoutBasic;
