import environment from "environment";
import EventEmitter from "eventemitter3";
import { parseJSON } from "utils/common";
import notifier from "simple-react-notifications";

class Socket extends EventEmitter {
  _socket = null;
  _connectedProcess = false;
  _reconnectedProcess = false;
  connected = false;
  disconnected = false;

  constructor() {
    super();
    if (!environment.prod) {
      window.socketSrv = this;
    }
  }

  get socket() {
    return this._socket;
  }

  set({ queryParams = "" }) {
    this.queryParams = queryParams;
  }

  connect = () => {
    if (this._connectedProcess && !this._reconnectedProcess) {
      return;
    }
    console.log("connect open");
    this._socket = new WebSocket(`${environment.socketUrl}${this.queryParams}`);
    this._socket.onopen = this._socketOnOpen;
    this._socket.onmessage = this._socketOnMessage;
    this._socket.onclose = this._socketClose;
    this._socket.onerror = this._socketError;
    this._connectedProcess = true;
  };

  _socketOnOpen = () => {
    this._connectedProcess = false;
    this._reconnectedProcess = false;
    this.connected = true;
    this.disconnected = false;
    this.emit("open");
  };

  _socketOnMessage = (e) => {
    const { event = "", payload } = parseJSON(e.data);
    this.emit(event, payload);
  };

  _socketClose = (e) => {
    if (!this.disconnected) {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      this.emit("disconnect");
      this._reconnectedProcess = true;
      this._socketRecconect();
    }
  };

  _socketRecconect() {
    setTimeout(() => {
      this.connect();
    }, 1000);
  }

  _socketError = (err) => {
    notifier.error("Connection error, please reload a page");
    console.error("Socket encountered error: ", err.message, "Closing socket");
    this.disconnect();
    if (this._reconnectedProcess) {
      this._socketRecconect();
    }
  };

  disconnect = () => {
    this.disconnected = true;
    this.connected = false;
    if (this._socket) {
      this._socket.close();
      this._socket = null;
    }
    if (!this._reconnectedProcess) {
      this.removeAllListeners();
    }
  };

  send = (message) => {
    this._socket &&
      this._socket.readyState === 1 &&
      this._socket.send(JSON.stringify(message));
  };
}

export default new Socket();
