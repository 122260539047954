import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { TextInput } from "components/form/input";
import { InputWithLabel } from "components/form/input-with-label";
import { Picker } from "components/form/data-picker";
import clsx from "clsx";
import config from "_config";
import { lotActions } from "store";

import Timer from "components/timer";
import ImagePreview from "components/image-preview";
import Upload from "components/upload";
import { Icon } from "components/icon";
import Button from "components/button";
import LayoutSimple from "components/layout/simple";
import {
  Col,
  Row,
  CenterContent,
  Left,
  Center,
  Right,
  Bid,
  BidWrap,
  BidInput,
  CloseWrap,
} from "./styled";
import { Text, SmallText, SmallTitle } from "components/styled";
import Spinner from "components/preloader";

function AddLot({
  mode,
  match: {
    params: { id, ...params },
    ...match
  },
}) {
  let initialValues = {};
  let content;
  const dispatch = useDispatch();
  const { loading, lot } = useSelector((state) => {
    return {
      lot: state.lot.lot,
      loading: state.lot.loading,
    };
  }, shallowEqual);

  useEffect(() => {
    if (mode === "create") {
    } else if (mode === "update" || mode === "copy") {
      dispatch(lotActions.getLotStart({ id }));
    }
  }, []);

  if (mode === "create") {
    initialValues = {
      startPrice: "",
      bidSteps: [],
      startAt: new Date(),
      endAt: new Date(),
      description: "",
      instruction: "",
      image: {},
    };
  } else if (mode === "update" || mode === "copy") {
    initialValues = {
      startPrice: lot.startPrice,
      bidSteps: lot.bidStepsSimple,
      startAt: lot.startAtSimple,
      endAt: lot.endAtSimple,
      description: lot.description,
      instruction: lot.instruction,
      image: {},
    };
  }

  const validationSchema = {
    startPrice: Yup.number().min(1).required(" Start price is required"),
    bidSteps: Yup.array()
      .of(Yup.number().min(config.bids.min))
      .unique("bid must be unique")
      .required("Bids are required"),
    startAt: Yup.date().required("Required").min(new Date()).nullable(),
    endAt: Yup.date().required("Required").min(new Date()).nullable(),
    description: Yup.string(),
    instruction: Yup.string(),
    image: Yup.object().nullable(),
  };

  const onSubmit = (values) => {
    // values.description = values.description.replace(/\n/g, "\n");
    if (mode === "create" || mode === "copy") {
      dispatch(lotActions.createLotStart(values));
    } else if (mode === "update") {
      dispatch(lotActions.updateLotStart({ id, ...values }));
    }
  };
  if (loading) {
    content = <Spinner />;
  } else {
    if (mode === "create" || lot.id) {
      content = (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, setFieldValue, isValid, errors, values }) => {
            return (
              <form onSubmit={handleSubmit} className="d-flex flex-wrap">
                <Col className="col-12 col-md-12 col-lg-12 col-xl-4">
                  {/* {mode === "update" ? (
                    <ImagePreview
                      color="grey"
                      mode="single"
                      image={lot.image}
                    />
                  ) : (
                    <Upload onChange={(item) => setFieldValue("image", item)} />
                  )} */}
                  <Upload
                    onChange={(item) => setFieldValue("image", item)}
                    initialImage={mode === "update" ? lot.image : null}
                  />
                  <Left>
                    <Text color="darkGrey" className="mt-5 mb-2 pl-2 medium">
                      Description:
                    </Text>
                    <TextInput
                      name="description"
                      type="area"
                      placeholder="Description"
                    />
                  </Left>
                </Col>
                <Col className="col-12 col-md-12 col-lg-6 col-xl-5">
                  <Center>
                    <div>
                      <Row>
                        <Text color="darkGrey" className="medium">
                          Start date :{" "}
                        </Text>
                        <Picker
                          name="startAt"
                          onChange={(value) => setFieldValue("startAt", value)}
                        />
                      </Row>
                      <Row>
                        <Text color="darkGrey" className="medium">
                          End date :{" "}
                        </Text>
                        <Picker
                          name="endAt"
                          onChange={(value) => setFieldValue("endAt", value)}
                        />
                      </Row>
                    </div>
                    <div className="">
                      <Text color="darkGrey" className="mt-4 mb-2 pl-2 medium">
                        Instruction:
                      </Text>
                      <TextInput
                        name="instruction"
                        type="area"
                        placeholder="Instruction:"
                      />
                    </div>
                  </Center>
                </Col>
                <Col className="col-12 col-md-12 col-lg-6 col-xl-3">
                  <Right>
                    <div>
                      <Row>
                        <Text color="darkGrey" className="medium">
                          Status:
                        </Text>
                        {mode === "update" ? (
                          <SmallTitle className="text-capitalize" color="black">
                            {lot.statusLabel}
                          </SmallTitle>
                        ) : (
                          ""
                        )}
                      </Row>
                      {mode === "update" && (
                        <Row>
                          <Text color="darkGrey" className="medium">
                            End in:
                          </Text>
                          <Timer
                            estimate={lot.estimate}
                            dayEstimate={lot.dayEstimate}
                          />
                        </Row>
                      )}

                      <Row className="mt-4">
                        <Text color="darkGrey" className="medium">
                          Start Price:
                        </Text>
                        <div className="d-flex align-items-center">
                          <Text color="darkGrey" className="medium mr-2">
                            $
                          </Text>
                          <TextInput
                            name="startPrice"
                            type="number"
                            theme="number"
                            mode="noError"
                          />
                        </div>
                      </Row>
                      <SmallTitle color="black" className="mt-5">
                        Bidding
                      </SmallTitle>

                      <FieldArray
                        name="bidSteps"
                        render={(arrayHelpers, ...rest) => {
                          return (
                            <BidWrap>
                              <>
                                {values.bidSteps.map((item, index) => (
                                  <Bid key={index}>
                                    <Field
                                      name={`bidSteps.${index}`}
                                      type="number"
                                      className="bidInput"
                                    />
                                    <CloseWrap
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Icon size="14" component="Close" />
                                    </CloseWrap>
                                  </Bid>
                                ))}
                                {values.bidSteps.length < 12 && (
                                  <Bid>
                                    <Button
                                      type="button"
                                      className="medium"
                                      mode="small"
                                      theme="fullWidth"
                                      color="lightYellow"
                                      textColor="green"
                                      onClick={() =>
                                        arrayHelpers.push(config.bids.min)
                                      }
                                    >
                                      + Add
                                    </Button>
                                  </Bid>
                                )}
                              </>
                            </BidWrap>
                          );
                        }}
                      />
                    </div>
                    <div
                      className={clsx(
                        "d-flex mb-4",
                        !false
                          ? "justify-content-end"
                          : "justify-content-between"
                      )}
                    >
                      {false && (
                        <Button
                          type="button"
                          className="medium"
                          mode="small"
                          color="lightYellow"
                          textColor="green"
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        mode="small"
                        type="submit"
                        color={isValid && !errors.length > 0 ? "red" : "grey"}
                        disabled={!isValid || errors.length > 0}
                      >
                        Save & Publish
                      </Button>
                    </div>
                  </Right>
                </Col>
                {Object.values(errors).length > 0 && (
                  <div className="col-12 mb-3">
                    {Object.values(errors).map((item, idx) => (
                      <SmallText
                        key={idx}
                        color="red"
                        className="text-center mt-2 medium"
                      >
                        {item}
                      </SmallText>
                    ))}
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      );
    }
  }
  return <LayoutSimple>{content}</LayoutSimple>;
}

export default AddLot;
