import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, device } from "components/variable";
import { rem } from "utils/common";

const ButtonWrap = styled.button`
  ${(props) =>
    props.theme === "fullWidth"
      ? css`
          width: 100%;
        `
      : css`
          width: ${(props) => (props.mode === "small" ? rem(150) : rem(210))};
        `};
  height: ${(props) => (props.mode === "small" ? rem(40) : rem(80))};
  padding: ${(props) => (props.mode === "small" ? rem(13) + 0 : rem(0))};
  border-radius: ${rem(129)};
  background: ${(props) => (props.color ? colors[props.color] : colors.red)};
  border: none;
  color: ${(props) =>
    props.textColor ? colors[props.textColor] : colors.white};
  font-size: ${(props) => (props.mode === "small" ? rem(18) : rem(24))};
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
  &:hover {
    transition: 0.3s;
    background: ${(props) => (props.textColor ? colors.grey : colors.green)};
  }
  @media ${device.tablet} {
  }
`;

const ButtonLinkWrap = styled(ButtonWrap)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

function Button({ children, ...props }) {
  return <ButtonWrap {...props}>{children}</ButtonWrap>;
}

export const ButtonLink = React.memo(({ children, href, theme, ...props }) => (
  <ButtonLinkWrap as={Link} to={href} {...props}>
    {children}
  </ButtonLinkWrap>
));

export default Button;
