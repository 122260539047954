import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { compose } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";

import EditLot from "containers/Admin/EditLot";
import AddLot from "containers/Admin/AddLot";
import KeyCodes from "containers/Admin/KeyCodes";
import UserList from "containers/Admin/user-list";

const AdminPanel = (props) => {
  const { match } = props;
  const dispatch = useDispatch();
  const { isLoading, isAuth } = useSelector((state) => {
    return {
      isAuth: state.auth.isAuth,
      isLoading: state.auth.isLoading,
    };
  }, shallowEqual);

  const path = {
    admin: `${match.path}`,
    userList: `${match.path}/user-list`,
    createLot: `${match.path}/add-lot`,
    copyLot: `${match.path}/add-lot/:id/`,
    editLot: `${match.path}/lot/:id/`,
    generate: `${match.path}/generate`,
  };
  return (
    <Switch>
      <Route exact path={path.userList} component={UserList} />
      <Route exact path={path.generate} component={KeyCodes} />
      <Route
        exact
        path={path.createLot}
        render={(props) => <AddLot {...props} mode="create" />}
      />
      <Route
        exact
        path={path.copyLot}
        render={(props) => <AddLot {...props} mode="copy" />}
      />
      <Route exact path={path.editLot} component={EditLot} />
      <Route path={path.admin} render={() => <Redirect to={path.userList} />} />
    </Switch>
  );
};

export default AdminPanel;
