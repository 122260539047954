import React from "react";
import clsx from "clsx";
import { Row } from "components/styled";
import { Icon } from "components/icon";
function LotSettingsPanel({
  onDelete,
  onEdit,
  onCopy,
  status,
  mode,
  ...props
}) {
  return (
    <Row
      {...props}
      className={clsx(
        "justify-content-end mt-5 mb-2",
        mode === "desktop" ? "d-none d-xl-flex" : ""
      )}
    >
      <Icon
        className="cursor-pointer pathHover"
        component="Trash"
        onClick={onDelete}
      />
      {status === "comingSoon" && (
        <Icon
          className="ml-4 cursor-pointer pathHover"
          component="Edit"
          onClick={onEdit}
        />
      )}
      <Icon
        className="ml-4 cursor-pointer pathHover"
        component="Multi"
        onClick={onCopy}
      />
    </Row>
  );
}

export default LotSettingsPanel;
