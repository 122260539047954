import moment from "moment";
import { UserModel } from "./user";
export class BidModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj._id || obj.id;
    this.value = obj.value;
    this.lotId = obj.lotId;
    this.user = new UserModel(obj.userId) || new UserModel(obj.user);
    this.createdAt = moment(obj.createdAt).format("MM/DD/YY, h:mm");
  }
}
